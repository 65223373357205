import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, ButtonGroup, Stack } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useDispatch } from "react-redux";

import {
  AppleLoginSvg,
  FacebookLoginSvg,
  GoogleLoginSvg,
} from "../../../assets";
import InputFiled from "../../custom-inputs/input-field";
import PasswordFiled from "../../custom-inputs/password-field";
import facebookLogin from "../../../services/facebook-login";
import apiManager from "../../../services/api-manager";
import { handleLoader, setToast, setUser } from "../../../store/reducer";
import { accessToken } from "../../../constant";
import useLocation from "../../../hooks/use-location";

export default function LoginForm() {
  const [screenType, setScreenType] = useState("USER");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const tabBtn = () => {
    return (
      <ButtonGroup
        // variant="outlined"
        aria-label="outlined button group"
        sx={{ height: { md: "52px" }, width: "100%" }}
        fullWidth
      >
        <Button
          variant={screenType === "USER" ? "contained" : "outlined"}
          onClick={() => setScreenType("USER")}
          sx={{ borderRadius: "10px" }}
        >
          User
        </Button>
        <Button
          variant={screenType === "PARLOUR" ? "contained" : "outlined"}
          sx={{ borderRadius: "10px" }}
          onClick={() => setScreenType("PARLOUR")}
        >
          Parlour
        </Button>
      </ButtonGroup>
    );
  };

  const login = async (token = '', type = null) => {
    if (!type) {
      dispatch(handleLoader(true));
    }
    try {
      let _fd = {}
      if (!type) {
        _fd.email = email;
        _fd.password = password;
      } else {
        _fd.token = token;
      }
      let { data } = await apiManager({ path: `/login${!type ? "" : "/" + type}`, method: "post", params: _fd })
      localStorage.setItem(accessToken, data?.token);
      dispatch(setUser(data.user));
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(error?.response?.data?.error)
      }
      dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
    } finally {
      dispatch(handleLoader(false));
    }
  }

  const handleFacebookLogin = async () => {
    // facebookLogin    
    dispatch(handleLoader(true));
    facebookLogin().then(result => {
      login(result, "facebook");
    }).catch(err => {
      console.log(err);
      dispatch(handleLoader(false));
    });
  };

  return (
    <div>
      {screenType === "USER" ? (
        <Box
          sx={{
            bgcolor: "background.paper",
            width: { md: "428px" },
            display: "flex",
            my: 5,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            px: { md: 2 },
          }}
        >
          <Stack width="100%" gap={2}>
            {tabBtn()}
            <SocialBtn
              bgcolor="#1877F2"
              text="Continue with Facebook"
              startIcon={FacebookLoginSvg}
              onClick={handleFacebookLogin}
            />
            <SocialBtn
              bgcolor="#4285F4"
              text="Continue with Google"
              startIcon={GoogleLoginSvg}
            />
            <SocialBtn
              bgcolor="#050708"
              text="Continue with Apple"
              startIcon={AppleLoginSvg}
            />
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            bgcolor: "background.paper",
            width: { md: "428px" },
            display: "flex",
            my: 5,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            px: { md: 2 },
          }}
        >
          <Stack width="100%">
            {tabBtn()}

            <Box my={2} component={"form"} onSubmit={e=>{e.preventDefault();login()}}>
              <InputFiled
                required
                value={email}
                error={formErrors?.email}
                name="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                inputStyle={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "12px!important",
                  },
                  background: "rgba(251, 251, 251, 0.7)",
                  mt: 1,
                }}
                className="formInput"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter your email"
              />

              <PasswordFiled
                required
                value={password}
                error={formErrors?.password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                inputStyle={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "12px!important",
                  },
                  background: "rgba(251, 251, 251, 0.7)",
                  mt: 1,
                }}
                className="formInput"
                placeholder="Enter your password"
              />

              <Button
                variant="contained"
                sx={{ borderRadius: "12px", py: { md: 2 }, mt: 2 }}
                fullWidth
                type="submit"
              >
                Login
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
    </div>
  );
}

const SocialBtn = ({ bgcolor, text, startIcon, ...props }) => {
  return (
    <Button
      variant="contained"
      sx={{
        background: bgcolor,
        justifyContent: "flex-start",
        padding: { md: "13px 0px 13px 30px;", xs: "8.5px 14px" },
        ":hover": {
          background: bgcolor,
          opacity: 0.9,
        },
        fontSize: { md: 16 },
        fontWeight: 500,
        borderRadius: "4px",
        "& .MuiButton-startIcon": {
          mr: { md: "12px" },
        },
        whiteSpace: "nowrap",
      }}
      startIcon={
        <Box
          component={"img"}
          src={startIcon}
          sx={{
            width: "24px",
            height: "24px",
            filter: "brightness(0) invert(1);",
          }}
        />
      }
      {...props}
    >
      {text}
    </Button>
  );
};
