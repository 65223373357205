import * as firebase from "@firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCUYT-lsZRYqpMYnNKwWOFstMQS7_LEyvA",
    authDomain: "parlour-c45d5.firebaseapp.com",
    projectId: "parlour-c45d5",
    storageBucket: "parlour-c45d5.appspot.com",
    messagingSenderId: "672009766378",
    appId: "1:672009766378:web:e943c6b96831b6403b5e93",
    measurementId: "G-WJ7ZPZK9GS"
};

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);
export default fire;