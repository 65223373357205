import React from 'react';
import { Avatar, Box, Rating, Skeleton, Stack } from '@mui/material';

function ReviewCardPlaceholder() {
    return (
        <Box sx={{ border: "1px solid #E7EAEC", p: 3 }}>
            <Stack direction="row" justifyContent="space-between">
                <Box mb={2}>
                    <Avatar sx={{
                        width: "68px",
                        height: "68px",
                        mb:1
                    }}>{""}</Avatar>
                    <Skeleton variant='square' height={25} width={150} />
                </Box>
                <Box>
                    <Rating name="read-only" readOnly value={5} />
                    <Skeleton variant='square' height={10} width="100%" />
                </Box>
            </Stack>
            <Skeleton variant='square' width="100%" height={150} />
        </Box>
    );
}

export default ReviewCardPlaceholder;