import React from "react";
import {
  Avatar,
  Box,
  Container,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import StarIcon from "@mui/icons-material/Star";
import Groups2Icon from "@mui/icons-material/Groups2";
import MenuIcon from "@mui/icons-material/Menu";
import TocIcon from "@mui/icons-material/Toc";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { LogoBlackSvg } from "../../assets";
import DropDown from "../dropdown/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { handleLoader, logoutUser, setToast } from "../../store/reducer";
import apiManager from "../../services/api-manager";
import { accessToken } from "../../constant";

function DashboardHeader() {
  const _route = window.location.pathname;
  const matches = useMediaQuery("(min-width:992px)");
  const { user } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();

  const logOut = async () => {
    try {
      dispatch(handleLoader(true));
      await apiManager({ method: "post", path: "logout" });
      localStorage.removeItem(accessToken);
      dispatch(logoutUser());
    } catch (error) {
      dispatch(setToast({ type: "error", message: "Error occurred!" }));
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const menuBtns = () => (
    <>
      <NavBtn
        to="/dashboard/reviews"
        currentRoute={_route}
        label="Reviews"
        icon={<StarIcon sx={{ color: "#F8A401" }} />}
      />
      <NavBtn
        to="/dashboard/parlour-form"
        currentRoute={_route}
        label="Parlour Form"
        icon={<InsertDriveFileIcon />}
      />
      <NavBtn
        to="/dashboard/services"
        currentRoute={_route}
        label="Services"
        icon={<InsertDriveFileIcon />}
      />
      <NavBtn
        to="/dashboard/team"
        currentRoute={_route}
        label="Team"
        icon={<TocIcon />}
      />
    </>
  );

  const avatarMenu = () => {
    return (
      <DropDown
        variant="text"
        color="tretiary"
        startIcon={
          <Avatar sx={{ width: 55, height: 55 }} src={user?.avatar}>
            {""}
          </Avatar>
        }
      >
        <Box sx={{ minWidth: 230 }}>
          <ListItemButton component={Link} to="/dashboard/profile">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Setting" />
          </ListItemButton>
          <ListItemButton onClick={logOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </Box>
      </DropDown>
    );
  };

  return (
    <Box component="header" sx={{ bgcolor: "#fff", color: "#333", py: 2 }}>
      <Container maxWidth="xxl">
        <Stack direction="row" alignItems="center">
          <Box>
            <Link to="/dashboard">
              <Avatar
                variant="square"
                src={LogoBlackSvg}
                sx={{ width: 90, height: "auto" }}
              >
                &nbsp;
              </Avatar>
            </Link>
          </Box>
          <Box sx={styles.navContainer}>
            {!matches ? (
              <DropDown btnText={<MenuIcon />}>
                <Box>{menuBtns()}</Box>
              </DropDown>
            ) : (
              menuBtns()
            )}

            {/* <Link to="/dashboard/profile">
              <Avatar src={"image"} sx={{ width: 55, height: 55 }} />
            </Link> */}
            {avatarMenu()}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

const NavBtn = ({
  to: propsTo,
  currentRoute,
  pathName,
  icon,
  label,
  ...props
}) => {
  const isActive = currentRoute.includes(propsTo);
  const matches = useMediaQuery("(min-width:992px)");

  return (
    <Box
      component={Link}
      to={propsTo}
      sx={styles.navBtn(matches)}
      className={isActive ? "active" : ""}
    >
      {icon}
      <span className="btn-text">{label}</span>
    </Box>
  );
};

export default DashboardHeader;

const styles = {
  navContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 2,
  },
  navBtn: (matches) => ({
    bgcolor: "#FBFBFB",
    border: matches ? "1px solid #E3E3E3" : "",
    color: "primary.main",
    textDecoration: "none",
    py: 1,
    px: 2,
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    transition: "0.2s all ease-in-out",
    "& .btn-text": {
      fontSize: "14px",
      paddingLeft: "5px",
    },
    "&:hover": {
      bgcolor: "primary.main",
      color: "#fff",
    },
    "&.active": {
      bgcolor: "primary.main",
      color: "#fff",
    },
  }),
};
