import { Grid, Skeleton,Stack } from "@mui/material";
import { Container } from "@mui/system";

const PostUiPlaceholder = () => (
    <>
        <Skeleton variant="rectangular" height={500} />
        <Container maxWidth="xxl">
            <Grid container spacing={2} mt={3}>
                <Grid item sm={6} xs={12}>
                    <Stack spacing={2}>
                        <Skeleton variant="rectangular" height={15} />
                        <Skeleton variant="rectangular" height={15} />
                        <Skeleton variant="rectangular" width="30%" height={15} />
                        <Skeleton variant="rectangular" height={250} />
                    </Stack>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Skeleton variant="rectangular" height={450} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={450} />
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                        <Skeleton variant="rectangular" width={45} height={15} />
                        <Skeleton variant="rectangular" width={45} height={15} />
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    </>
)
export default PostUiPlaceholder