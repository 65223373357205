import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import apiManager from "../../services/api-manager";
import { setToast } from "../../store/reducer";
import InputField from "../custom-inputs/input-field";
import SelectBox from "../custom-inputs/select-box";

const ContactForm = ({ title = "Contact Us", text = "Any question or remarks? Just write us a message!" }) => {
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleInput = async (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setFormErrors({});
        try {
            let { data } = await apiManager({ path: "user/contact", method: "post", params: formData })
            dispatch(setToast({ type: "success", message: data?.message }));
            setFormData({})
        } catch (error) {
            if (error?.response?.status === 422) {
                setFormErrors(error?.response?.data?.error);
            }
            dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <Box>
            <Typography
                variant="h4"
                color="initial"
                fontSize={{ md: 68, sm: 48, xs: 30 }}
                fontWeight={400}
                fontFamily={"Parisienne"}
                textAlign={"left"}
            >
                {title}
            </Typography>
            <Typography
                variant="subtitle1"
                color="initial"
                component={"p"}
                fontWeight={500}
                fontSize={{ md: 18, sm: 12 }}
                textAlign={"left"}
            >
                {text}
            </Typography>
            <Box component={"form"} onSubmit={handleFormSubmit}>
                <Box my={3}>
                    <InputField label="Name" name="name" value={formData?.name} error={formErrors?.name} onChange={handleInput} required />
                </Box>
                <Box my={3}>
                    <InputField label="Email" name="email" value={formData?.email} error={formErrors?.email} onChange={handleInput} required type="email" />
                </Box>
                <Box my={3}>
                    <InputField label="Phone number" name="phone_number" value={formData?.phone_number} error={formErrors?.phone_number} onChange={handleInput} required type="number" />
                </Box>
                <Box my={3}>
                    <SelectBox label="How can We Help you?" required items={[{ label: "Subject 1", value: "Subject 1" }, { label: "Subject 2", value: "Subject 2" }]} name="subject" value={formData?.subject} error={formErrors?.subject} onChange={handleInput} />
                </Box>
                <Box my={3}>
                    <InputField label="Write your message..." required multiline rows="4" name="message" value={formData?.message} error={formErrors?.message} onChange={handleInput} />
                </Box>
                <Box my={3}>
                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        type="submit"
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress sx={{ color: "#ccc" }} size={20} /> : null}&nbsp;&nbsp;&nbsp;Send message
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ContactForm;
