import { post1 } from "../../assets"

const postData={
    image_url:post1,
    title:"Wedding",
    description:"Here are some of the top tips to enhance your wedding look. Here are some of the top tips to enhance your wedding look. lorem ipsum",
    created_at:"5 mins read",
}

export {
    postData
}