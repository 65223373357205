import { PackagesCardDummySvg } from "../../../assets";
import Utils from "../../../utils/utils";

export const servicesJSON = [
  {
    id: Utils.generateId(),
    imgUrl: PackagesCardDummySvg,
    title: "Noor Ul Hasna",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus ",
    createdAt: "",
    status: "pending",
  },
  {
    id: Utils.generateId(),
    imgUrl: PackagesCardDummySvg,
    title: "Shahid Khan Yousidf",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus ",
    createdAt: "",
    status: "accepted",
  },
  {
    id: Utils.generateId(),
    imgUrl: PackagesCardDummySvg,
    title: "Alvina Mallah",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus ",
    createdAt: "",
    status: "rejected",
  },
];

export const packagesData = [
  {
    icon: PackagesCardDummySvg,
    title: "Bridal packages",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    status: true,
  },
  {
    icon: PackagesCardDummySvg,
    title: "Eyebrow and eyelash treatments",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    status: true,
  },
  {
    icon: PackagesCardDummySvg,
    title: "Makeup application",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    status: true,
  },
  {
    icon: PackagesCardDummySvg,
    title: "Hair treatments",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    status: true,
  },
  {
    icon: PackagesCardDummySvg,
    title: "Hair coloring",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    status: false,
  },
  {
    icon: PackagesCardDummySvg,
    title: "Haircuts and styling",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    status: false,
  },
  {
    icon: PackagesCardDummySvg,
    title: "Waxing and threading",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    status: false,
  },
];
