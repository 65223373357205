import React from "react";
import {
  Box,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";


const ParlourDisplayCardPlaceholder = () => {
  return (
    <Paper
      sx={{
        width: "100%",
        minHeight: "385px",
        left: "72px",
        top: "764px",
        background: "#ffff",
        p: "13px",
        transition: "transform .3s ease-out",
        borderRadius: "8px",
        ":hover": {
          transform: "translate(0% ,-1%)",
          filter: "drop-shadow(0px 6px 27px rgba(0, 0, 0, 0.09))",
        },
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Skeleton
          sx={{
            borderRadius: "8px",
            width: "100%",
            minWidth: "200px",
            height: "250px",
            transform: "scale(1, 0.9);",
          }}
        />
      </Box>
      <Box mt={"19px"}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Skeleton
            variant="text"
            fontSize={{ md: 18, sm: 15, xs: 14 }}
            width={"50%"}
          />

          <Skeleton variant="rectangular" width={70} />
        </Stack>

        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          mt={"5px"}
        >
          <Skeleton
            variant="text"
            fontSize={{ md: 14, sm: 12, xs: 9 }}
            width={"40%"}
          />

          <Skeleton
            variant="text"
            fontSize={{ md: 14, sm: 12, xs: 9 }}
            width={"30%"}
          />
        </Stack>

        <Typography variant="body1" color="#7E7D7A" fontSize={12} mt={"15px"}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
        <Skeleton width={"100%"} height={60} sx={{ mt: 2 }} />
      </Box>
    </Paper>
  );
};

export default ParlourDisplayCardPlaceholder;
