import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import { useDispatch } from "react-redux";

import { setToast } from "../../../store/reducer";
import apiManager from "../../../services/api-manager";
import InputField from "../../custom-inputs/input-field";
import PasswordField from "../../custom-inputs/password-field";
import LoadingButton from "../../loading-button/loading-button";

export default function RegistrationForm() {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }

  const handleRegistration = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      let { data } = await apiManager({ path: "/register", method: "post", params: formData})
      dispatch(setToast({ type: "success", message: data?.message }));
      dispatch(setToast({ type: "success", message: "Please use your credentials to login your account." }));
      setFormData({})
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(error?.response?.data?.error)
      }
      dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Box
        sx={{
          bgcolor: "background.paper",
          width: { md: "428px" },
          display: "flex",
          my: 5,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          px: { md: 2 },
        }}
      >
        <Stack width="100%">
          <Typography sx={{ textAlign: "center", color: "primary.main" }} variant="h6">
            Registration
          </Typography>
          <Box my={2} component={"form"} onSubmit={handleRegistration}>
            <InputField
              required
              value={formData?.email}
              error={formErrors?.email}
              name="email"
              type="email"
              onChange={handleChange}
              inputStyle={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "12px!important",
                },
                background: "rgba(251, 251, 251, 0.7)",
                mt: 1,
              }}
              className="formInput"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Enter your email"
            />

            <PasswordField
              required
              value={formData?.password}
              error={formErrors?.password}
              name="password"
              onChange={handleChange}
              inputStyle={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "12px!important",
                },
                background: "rgba(251, 251, 251, 0.7)",
                mt: 1,
              }}
              className="formInput"
              placeholder="Enter your password"
            />

            <InputField
              type="number"
              value={formData?.phone}
              error={formErrors?.phone}
              name="phone"
              onChange={handleChange}
              inputStyle={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "12px!important",
                },
                background: "rgba(251, 251, 251, 0.7)",
                mt: 1,
              }}

              className="formInput"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Enter your number"
            />
            <LoadingButton
              variant="contained"
              sx={{ borderRadius: "12px", py: { md: 2 }, mt: 2 }}
              fullWidth
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Login
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
    </div>
  );
}
