import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import {
  DeleteIconSvg,
  EditIconSvg,
  ProfileCardDummySvg,
} from "../../assets";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DropDown from "../dropdown/dropdown";
import { useNavigate } from "react-router-dom";

const TeamCard = ({
  data = {},
  each = {},
  handleRemove = e => { },
  menu = false,
}) => {
  const navigate = useNavigate();

  const myDropDown = () => (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        mt: 1,
        mr: 2,
        display: !menu && "none",
      }}
    >
      <DropDown useIconBtn icon={<MoreHorizIcon sx={{ color: "#fff" }} />}>
        <MyMenuItem
          onClick={() => navigate(`/dashboard/team/${data?._id}/update`)}
          icon={EditIconSvg}
          label="Edit"
        />
        <MyMenuItem
          onClick={() => handleRemove(data?._id)}
          icon={DeleteIconSvg}
          label="Remove"
        />
      </DropDown>
    </Box>
  )

  return (
    <Card elevation={0} sx={{ minWidth: "318" }}>
      <Box sx={{ position: "relative" }}>
        {myDropDown()}
        <CardMedia
          component="img"
          image={data?.profile_picture?.url}
          alt={data?.full_name + ".Our team member."}
          sx={{ maxHeight: "300px", minHeight: 100, backgroundColor: "#ccc" }}
        />
      </Box>
      <CardContent sx={{ padding: { lg: "26px 0px 0px 7px" } }}>
        <Typography
          variant="h3"
          fontSize={{ md: "28px", xs: "22px" }}
          fontWeight={600}
          children={data?.full_name}
        />
        <Typography
          variant="subtitle1"
          component={"span"}
          fontSize={{ md: 20, xs: 18 }}
          fontWeight={500}
          color="text.secondary"
          mt={"10px"}
          mb={"18px"}
          children={data?.designation}
        />
        <Typography variant="body2" component="p" color="text.secondary">
          {(data?.description?.length && data?.description?.length >= 100)
            ? data?.description.substr(0, 100) + "..."
            : data?.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

TeamCard.defaultProps = {
  data: {
    name: "Nazia Siddiqui",
    role: "Owner",
    image: ProfileCardDummySvg,
    description: "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    owner: true
  },
  each: {
    name: "Nazia Siddiqui",
    role: "Owner",
    image: ProfileCardDummySvg,
    description: "Lorem ipsum dolor sit amet consectetur. Tortor aliquam nibh. amet consectetur amet consectetur. Tortr...",
    owner: true
  }
}

const MyMenuItem = ({ icon, label, ...props }) => {
  return (
    <ListItemButton dense {...props}>
      <ListItemIcon sx={{ minWidth: 36 }}>
        <Box component={"img"} src={icon} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

export default TeamCard;
