import {
  Blog,
  Home,
  Page404,
  ContactUs,
  Post,
  PrivacyPolicy,
  ParlourHome,
  UserProfile,
  TermsAndConditions,
  Dashboard,
  DashProfile,
  DashTeamForm,
  DashParlourForm,
  DashReviewPanel,
  ParlourPage,
  DashTeamListing,
  DashServices,
} from "../screens";

export const appRoutes = [
  {
    routeName: "/",
    ele: <Home />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/login",
    _for: "all",
  },
  {
    routeName: "/parlours",
    ele: <ParlourHome />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/parlours/:id",
    ele: <ParlourPage />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/profile",
    ele: <UserProfile />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/blog",
    ele: <Blog />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/login",
    _for: "all",
  },
  {
    routeName: "/post/:postId",
    ele: <Post />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/login",
    _for: "all",
  },

  {
    routeName: "/contact-us",
    ele: <ContactUs />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/login",
    _for: "all",
  },
  {
    routeName: "/blog",
    ele: <Blog />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/login",
    _for: "all",
  },
  {
    routeName: "/post/:postId",
    ele: <Post />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/login",
    _for: "all",
  },
  {
    routeName: "/privacy-policy",
    ele: <PrivacyPolicy />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/login",
    _for: "all",
  },
  {
    routeName: "/terms-and-conditions",
    ele: <TermsAndConditions />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/login",
    _for: "all",
  },
  {
    routeName: "/dashboard",
    ele: <Dashboard />,
    exact: true,
    isProtected: true,
    layout: "none",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/dashboard/profile",
    ele: <DashProfile />,
    exact: true,
    isProtected: true,
    layout: "none",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/dashboard/team",
    ele: <DashTeamListing />,
    exact: true,
    isProtected: true,
    layout: "none",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/dashboard/team/add",
    ele: <DashTeamForm />,
    exact: true,
    isProtected: true,
    layout: "none",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/dashboard/team/:id/update",
    ele: <DashTeamForm />,
    exact: true,
    isProtected: true,
    layout: "none",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/dashboard/parlour-form",
    ele: <DashParlourForm />,
    exact: true,
    isProtected: true,
    layout: "none",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/dashboard/reviews",
    ele: <DashReviewPanel />,
    exact: true,
    isProtected: true,
    layout: "none",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "/dashboard/services",
    ele: <DashServices />,
    exact: true,
    isProtected: true,
    layout: "none",
    fallBack: "/",
    _for: "all",
  },
  {
    routeName: "*",
    ele: <Page404 />,
    exact: true,
    isProtected: false,
    layout: "primary",
    fallBack: "/",
    _for: "all",
  },
];
