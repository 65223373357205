import React from 'react';
import { Grid, Typography, Stack, Box, Avatar, MenuItem, Button } from '@mui/material';
import moment from 'moment/moment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import Utils from '../../../utils/utils';
import { DropDown } from '../../../components';

function ReviewCard({ data, handleStatusChange: propsClick = (data, status) => { }, ...props }) {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const readMore = () => setIsExpanded(!isExpanded)

    const truncatedParagraph = data?.comment?.length ? data?.comment.slice(0, 150) : "";
    
    const renderDropDown = () => (
        <DropDown
            useIconBtn
            size="small"
            icon={<MoreHorizIcon sx={{ fontSize: 32, color: "#D9D9D9" }} />}
        >
            <MenuItem
                onClick={() => propsClick(data, "accepted")}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <CloseIcon color='success' />
                    <span>Accepted</span>
                </Stack>
            </MenuItem>
            <MenuItem
                onClick={() => propsClick(data, "rejected")}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <DoneIcon color='error' />
                    <span>Rejected</span>
                </Stack>
            </MenuItem>
        </DropDown>
    )

    return (
        <Grid item sm={6} xs={12}>
            <Box component={Stack} border="1px solid #ccc" borderRadius={3} p={2} maxWidth="100%" flexDirection="row" alignItems="center">
                <Avatar
                    src={data?.user?.avatar}
                    sx={{
                        width: 85,
                        height: 85,
                    }}
                >{""}</Avatar>
                <Box sx={{ flex: 1, pl: 2 }}>
                    <Stack mb={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography
                            children={data?.user?.name}
                            sx={styles.title}
                        />
                        {data?.status!=="accepted" && renderDropDown()}
                    </Stack>
                    <Typography
                        sx={styles.reviewText}
                    >
                        {!isExpanded ? truncatedParagraph : data?.comment}
                        {data?.comment?.length > 150
                            ? <Button onClick={readMore} variant="text"> {isExpanded ? " read less" : " ...read more"}.</Button> : ""}
                    </Typography>
                    <Stack direction="row" mt={1} alignItems="center" justifyContent="space-between">
                        <Typography
                            children={moment(data?.createdAt).format("MMM D YYYY ")}
                            sx={styles.dateText}
                        />
                        <Box
                            sx={styles.statusText(data?.status)}
                        >
                            {Utils.capitalize(data?.status)}
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Grid>
    );
}

export default ReviewCard;

const styles = {
    title: {
        fontSize: { md: 24, xs: 18 },
        fontWeight: 600,
        color: "#333",
    },
    subTitle: {
        fontSize: { md: 26, xs: 20 },
        mt: 2,
    },
    reviewText: {
        fontSize: 14,
        fontWeight: 300
    },
    dateText: {
        fontSize: 12,
        fontWeight: 300
    },
    statusText: status => ({
        fontSize: 14,
        fontWeight: 300,
        color: "#fff",
        borderRadius: 3,
        px: 2,
        py: 1,
        bgcolor: (status === "pending" ? "warning" : status === "rejected" ? "error" : "success") + ".main",
    }),
}