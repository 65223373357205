import axios from 'axios';
import { accessToken } from '../constant';

const _baseUrl = process.env.REACT_APP_API_KEY;

export default function apiManager({
    header = { 'Content-Type': 'application/json' },
    method = "get",
    path = "",
    params = {},
    baseUrl = _baseUrl,
    token = localStorage.getItem(accessToken)
}) {
    const HEADER = { headers: token ? { Authorization: `Bearer ${token}`, ...header } : header };

    if (method === 'post') {
        return axios[method](baseUrl + path, params, HEADER)
          .then(response => {
            return response;
        })
        .catch(err => {
            throw err;
        });
    }
    
      return axios[method](baseUrl + path, HEADER)
      .then(response => {
          return response;
        })
        .catch(err => {
          throw err;
        });
};