import React, { useEffect, useState } from 'react';
import { Grid, Typography, Container, Box, Stack, Button, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';

import { DashboardHeader, FileUploader, InputField, LoadingButton, SelectBox } from '../../../../components';
import usePageTitle from '../../../../hooks/use-page-title';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleLoader, setToast } from '../../../../store/reducer';
import apiManager from '../../../../services/api-manager';

function DashTeamForm() {
    const { id = null } = useParams();
    usePageTitle(!id ? "Add Team" : "Update Team");
    const [formData, setFormData] = useState({
        full_name: "",
        designation: "",
        description: "",
        picture_url: "",
        profile_picture: "",
        status: "active"
    });
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const goBack = () => navigate("/dashboard/team")

    useEffect(() => {
        async function init() {
            if (!id) {
                return
            }
            try {
                dispatch(handleLoader(true));
                let { data } = await apiManager({ method: 'get', path: `parlour/teams/${id}` });
                setFormData(data?.data);
            } catch (error) {
                dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
            } finally {
                dispatch(handleLoader(false));
            }
        }
        init()
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    const handleImage = (files, _for) => {
        const file = files[0];
        setFormData(prev => ({ ...prev, profile_picture: file, picture_url: URL.createObjectURL(file) }))
    }

    const handleForm = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            let { data } = await apiManager({
                path: !id ? "parlour/teams/add" : `parlour/teams/${id}/update`,
                method: "post",
                params: formData,
                header: { 'Content-Type': 'multipart/form-data' }
            });
            dispatch(setToast({ type: "success", message: data?.message }));
            goBack();
        } catch (error) {
            if (error?.response?.status === 422) {
                setFormErrors(error?.response?.data?.error);
            }
            dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <DashboardHeader />
            <Container maxWidth="xxl">
                <Grid container spacing={2} pb={4}>
                    <Grid item xs={12}>
                        <Typography children="Team Member Profile" sx={styles.subTitle} />
                        <Typography children="Lorem ipsum dolor sit amet consectetur. Nullam bibendum." sx={styles.text} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" onSubmit={handleForm} maxWidth="850px">
                            <Stack direction="column" spacing={2} mb={4} mt={2}>
                                <InputField placeholder="Full name" labelTop="Full Name" error={formErrors?.full_name} br={1} value={formData.full_name} name="full_name" onChange={handleInputChange} />
                                <InputField placeholder="Designation" labelTop="Designation" br={1} error={formErrors?.designation} value={formData.designation} name="designation" onChange={handleInputChange} />
                                <InputField placeholder="Description..." labelTop="Description" multiline rows="4" br={1} multiple error={formErrors?.description} value={formData.description} name="description" onChange={handleInputChange} />
                                <SelectBox
                                    error={formErrors?.status}
                                    items={[{ label: 'Active', value: "active" }, { label: 'Disabled', value: "disabled" }]}
                                    placeholder="Status" labelTop="status" br={1} value={formData.status} name="status" onChange={handleInputChange}
                                />
                                <FileUploader label="Please select your profile picture" callBack={files => handleImage(files, "profile_picture")} />
                                <Box>
                                    <FormControlLabel control={<Checkbox checked={Boolean(formData?.is_owner)} onChange={(e) => setFormData(prev => ({ ...prev, is_owner: e.target.checked }))} />} label="Owner" />
                                    <FormHelperText>From team members please select this check only for owner. Only one team member can became owner.</FormHelperText>
                                </Box>
                            </Stack>
                            <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2} >
                                <LoadingButton variant="contained" sx={{ px: 10 }} loading={isLoading} disabled={isLoading} type="submit" children="Save" />
                                <Button variant="text" sx={{ px: 10 }} onClick={goBack}>Cancel</Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default DashTeamForm;

const styles = {
    subTitle: {
        fontSize: { md: 26, xs: 20 },
        mt: 2,
    },
    text: {
        fontSize: 14,
        color: "#A6A6A6",
    },
}