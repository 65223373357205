import React, { useEffect, useState } from "react";
import {
  Box,
  InputBase,
  Rating,
  Stack,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";

import { LoadingButton, ModalWrapper } from "../../../../components";
import { RatingFilled, RatingFilledDark, ThankYouSvg } from "../../../../assets";
import apiManager from "../../../../services/api-manager";
import { useDispatch } from "react-redux";
import { handleLoader, setToast } from "../../../../store/reducer";

const ReviewModal = ({ open, parlour_id, handleClose }) => {
  const matches = useMediaQuery("(min-width:600px)");
  const [screenType, setScreenType] = useState("REVIEW");
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const handleReviewRequest = async (e) => {
    e.preventDefault();
    dispatch(handleLoader(true));
    setFormErrors({})
    try {
      let { data } = await apiManager({ method: "post", path: "user/review/create", params: { rating, comment, parlour_id } })
      setScreenType("Submit");
      dispatch(setToast({ type: "success", message: data?.message }))
      setComment("");
      setRating(1);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(error?.response?.data?.error)
      }
      dispatch(setToast({ type: "error", message: error?.response?.data?.message }))
    } finally {
      dispatch(handleLoader(false))
    }
  }

  return (
    <div>
      <ModalWrapper
        open={open}
        handleClose={
          screenType === "REVIEW"
            ? handleClose
            : () => {
              handleClose();
              setScreenType("REVIEW");
            }
        }
      >
        {screenType === "REVIEW" ? (
          <Box
            sx={{ width: { sm: "500px", xs: "100%" }, height: { sm: "500px" } }}
            component="form"
            onSubmit={handleReviewRequest}
          >
            <Typography
              variant="h4"
              color="initial"
              fontSize={{ sm: "24px", xs: "18px" }}
              fontWeight={500}
              textAlign="Center"
            >
              Write your about experience
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="initial"
              fontSize={{ sm: "24px", xs: "16px" }}
              fontWeight={400}
              textAlign="Center"
              mt={{ sm: 5, xs: 3 }}
            >
              How was your exoperience
            </Typography>
            <Stack justifyContent="center" alignItems="center" mt={2}>
              <Rating
                name="size-large"
                value={rating}
                size={matches ? "small" : "large"}
                sx={{
                  "& .css-dqr9h-MuiRating-label span": {
                    mr: 2,
                  },
                }}
                icon={
                  <Box
                    component={"img"}
                    src={RatingFilled}
                    sx={{ width: { sm: "100%", xs: "20px" } }}
                  />
                }
                emptyIcon={
                  <Box
                    component={"img"}
                    src={RatingFilledDark}
                    sx={{ width: { sm: "100%", xs: "20px" } }}
                  />
                }
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
            </Stack>
            <Box mt={3}>
              <InputBase
                multiline
                rows={8}
                sx={{ background: "#F6F6F6", p: 2 }}
                fullWidth
                placeholder="Write your review Here..."
                value={comment}
                onChange={e => setComment(e.target.value)}
                error={formErrors.comment}
              />
            </Box>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, borderRadius: "12px", height: "50px" }}
              type="submit"
            >
              Submit
            </LoadingButton>
          </Box>
        ) : (
          <Box
            sx={{ width: { sm: "500px", xs: "100%" }, height: { sm: "500px" } }}
          >
            <Stack justifyContent={"Center"} alignItems="center">
              <Box>
                <Box
                  component={"img"}
                  src={ThankYouSvg}
                  sx={{ width: "100%", maxWidth: "280px" }}
                />
              </Box>
            </Stack>
            <Typography
              variant="h3"
              color="initial"
              textAlign={"center"}
              fontSize={"38px"}
              fontWeight={"600"}
            >
              {" "}
              Thank you{" "}
            </Typography>
            <Typography
              variant="h3"
              color="initial"
              textAlign={"center"}
              fontSize={"24px"}
              fontWeight={"400"}
              my={2}
            >
              {" "}
              Review submitted, will get published when accepted by Owner.{" "}
            </Typography>
          </Box>
        )}
      </ModalWrapper>
    </div>
  );
};

export default ReviewModal;
