import React, { Fragment, useLayoutEffect, useRef } from "react";
import { Box, CircularProgress, ClickAwayListener, FormHelperText, InputLabel, MenuItem, Paper, TextField, Typography, InputAdornment } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import MyLocationIcon from '@mui/icons-material/MyLocation';

import Utils from "../../utils/utils";

var options = {
    componentRestrictions: { country: "pk" }
};

const AutoCompletePlace = ({ labelTop, helperText = "", error = "", initValue, callBack = () => { }, props }) => {
    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        options
    });
    const ref = useRef();
    const _id = "input_"+Utils.generateId();

    useLayoutEffect(() => {
        if (initValue) {
            ref.current.value = initValue;
        }
    }, []);

    const printHelperText = () => {
        if (helperText !== "") {
            return (
                <FormHelperText
                    sx={{
                        mt: "0 !important",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: "#6C6A6A",
                        fontWeight: 500,
                    }}
                >
                    {helperText}
                </FormHelperText>
            );
        }
    };

    const printError = () => {
        if (error !== "") {
            return (
                <FormHelperText sx={{ color: "red", mt: "0 !important" }}>
                    {error}
                </FormHelperText>
            );
        }
    };

    // useEffect(() => {
    //     if (placePredictions.length)
    //         placesService?.getDetails(
    //             {
    //                 placeId: placePredictions[0].place_id,
    //             },
    //             (placeDetails) => console.log(">>>", placeDetails)
    //         );
    // }, [placePredictions]);

    const renderItem = (item) => {
        return (
            <MenuItem onClick={() => {
                ref.current.value = item?.description;
                callBack(item?.description);
                getPlacePredictions({ input: "" });
            }}>
                <Typography sx={{ whiteSpace: "normal" }}>
                    {item?.description}
                </Typography>
            </MenuItem>
        )
    }

    return (
        <>
            {labelTop && (
                <InputLabel
                    htmlFor={_id}
                    sx={{
                        marginBottom: "-10px !important",
                        color: "#000",
                    }}
                >
                    {labelTop}
                </InputLabel>
            )}
            <TextField
                id={_id}
                inputRef={ref}
                placeholder="Address..."
                onChange={(evt) => {
                    getPlacePredictions({ input: evt.target.value });
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end"><MyLocationIcon/></InputAdornment>,
                }}
                error={Boolean(error !== "")}
                {...props}
            />
            {isPlacePredictionsLoading ? <Box sx={{ position: "relative" }}>
                <Box
                    sx={[styles.absBox, { textAlign: "center" }]}
                    component={Paper}
                >
                    <CircularProgress size={26} />
                </Box>
            </Box> : placePredictions.length ?
                <Box sx={{ position: "relative" }}>
                    <ClickAwayListener onClickAway={() => getPlacePredictions({ input: "" })}>
                        <Box
                            sx={styles.absBox}
                            component={Paper}
                        >
                            {placePredictions.map((item) => <Fragment key={Utils.generateId()}>{renderItem(item)}</Fragment>)}
                        </Box>
                    </ClickAwayListener>
                </Box>
                : null}
            {printError()}
            {printHelperText()}
        </>
    );
};

export default AutoCompletePlace;

const styles = {
    absBox: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 999,
        width: "100%",
        py: 1
    }
}