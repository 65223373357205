import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
  Box,
  Skeleton,
  Avatar,
} from "@mui/material";

import { Banner } from "../../components";
import { postJson } from "./data";
import usePageTitle from "../../hooks/use-page-title";
import { PostUiPlaceholder } from "../../placeholders";

function Post() {
  usePageTitle("Post");
  const [isLoading, setIsLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    function init() {
      setTimeout(() => {
        setIsLoading(false);
        setPostData(postJson);
      }, 3000);
    }
    init();
  }, []);

  if (isLoading) {
    return <PostUiPlaceholder />;
  }

  const Item = ({ title, text, ...props }) => (
    <Stack {...props} direction="row" gap={1} alignItems="center">
      <Typography children={title} sx={styles.title} />
      <Box sx={styles.line} />
      <Typography color="#ccc" children={text} sx={styles.duration} />
    </Stack>
  );

  return (
    <>
      <Banner
        bgImage={postData.bannerImage}
        bgPosition={{ md: "center", xs: "center right" }}
        title={postData.bannerTitle}
        subText={postData.bannerSubTitle}
      />
      <Container maxWidth="100%" sx={styles.container}>
        <Grid container spacing={2} pt={4}>
          <Grid item sm={6} xs={12}>
            <Typography children={postData.heading} sx={styles.heading} />
            <Item title="Author" text={postData.author} my={2} />
            <Item title="Published" text={postData.published} />
            <Typography
              children={postData.description}
              sx={styles.description}
            />
            <Typography
              children={postData.description}
              sx={styles.description}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box>
              <Avatar
                variant="square"
                alt="img.png"
                src={postData?.sideImg}
                sx={{
                  width: "100%",
                  maxWidth: "450",
                  height: "auto",
                  bgcolor: "primary.main",
                  path: { visibility: "hidden" },
                }}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12}>
                        <Box>
                            <video controls width="100%" height="450px" style={{ backgroundColor: "#333" }}>
                                <source src={postData.videoUrl} type="text/html" />
                            </video>
                        </Box>
                    </Grid> */}
        </Grid>
      </Container>
    </>
  );
}

const styles = {
  container: { width: "100%", pb: 3, backgroundColor: "#fff" },
  heading: {
    color: "#333",
    fontSize: { md: 32, sm: 26, xs: 22 },
  },
  title: {
    color: "primary.main",
    fontSize: "14px",
  },
  duration: {
    color: "#333",
    fontSize: "14px",
  },
  description: {
    fontSize: "16px",
    mt: 4,
  },
  line: {
    bgcolor: "#333",
    width: "25px",
    height: "1px",
  },
};

export default Post;
