import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Container, FormControlLabel, Checkbox, MenuItem, Stack, Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useDispatch } from 'react-redux';

import { DashboardHeader, DropDown, MyPagination } from '../../../components';
import usePageTitle from '../../../hooks/use-page-title';
import ReviewCard from './review-card';
import { reviewsJSON } from './data';
import { DashReviewCardPlaceHolder } from '../../../placeholders';
import { handleLoader, setToast } from '../../../store/reducer';
import apiManager from '../../../services/api-manager';

function DashReviewPanel() {
    usePageTitle("Review");
    const [records, setRecords] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [filterState, setFilterState] = useState("all");
    const prevFilterStateRef = useRef(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const dispatch = useDispatch();

    useEffect(() => {
        async function init() {
            if (prevFilterStateRef.current !== filterState && page !== 1) {
                return setPage(1);
            }
            try {
                setIsLoading(true);
                let { data } = await apiManager({ path: `parlour/reviews/get?page=${page}&per_page=${perPage}&status=${filterState === "all" ? "" : filterState}`, method: "get" });
                if (records?.data.length && page!==1) {
                    setRecords(prev => ({ ...prev, data: prev?.data.concat(data?.data?.data) }));
                } else {
                    setRecords(data?.data);
                }
            } catch (error) {
                dispatch(setToast({ type: "error", message: error?.response?.data?.message }))
            } finally {
                setIsLoading(false);
            }
        }
        init();
        prevFilterStateRef.current = filterState;
    }, [filterState, perPage, page]);

    const handleStatusChange = async (item, status) => {
        const { _id: id } = item;
        dispatch(handleLoader(true));
        try {
            let { data } = await apiManager({
                method: "post", path: `parlour/review/${id}/update-status`, params: { status }
            })
            dispatch(setToast({ type: "success", message: data?.message }));
            let index = records?.data.findIndex(item => item?._id === id);
            setRecords(prev => {
                let arr = prev.data
                arr[index].status = status
                return { ...prev, data: arr }
            })
        } catch (error) {
            dispatch(setToast({ type: "error", message: error?.response?.data?.message }))
        } finally {
            dispatch(handleLoader(false));
        }
    }

    const PlaceHolderDummy = () => {
        return Array(5)
            .fill("")
            .map((_, i) => (
                <DashReviewCardPlaceHolder key={i} />
            ))
    }

    return (
        <>
            <DashboardHeader />
            <Container maxWidth="xxl" >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography children="Reviews Panel" sx={styles.title} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack alignItems="flex-end" mb={3}>
                            <DropDown
                                variant="contained"
                                endIcon={<FilterAltIcon />}
                                btnText="Filters">
                                <MenuItem sx={styles.formControlLabel}
                                    onClick={() => setFilterState("all")}
                                    selected={filterState === "all"}>
                                    All
                                </MenuItem>
                                <MenuItem sx={styles.formControlLabel}
                                    onClick={() => setFilterState("accepted")}
                                    selected={filterState === "accepted"}>
                                    Accepted
                                </MenuItem>
                                <MenuItem sx={styles.formControlLabel}
                                    onClick={() => setFilterState("rejected")}
                                    selected={filterState === "rejected"}>
                                    Rejected
                                </MenuItem>
                                <MenuItem sx={styles.formControlLabel}
                                    onClick={() => setFilterState("pending")}
                                    selected={filterState === "pending"}>
                                    Pending
                                </MenuItem>
                            </DropDown>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ pb: 4 }}>
                    {isLoading && !records?.data?.length ? <PlaceHolderDummy /> : null}
                    {records?.data?.length ? records?.data?.map((item, i) => <ReviewCard handleStatusChange={handleStatusChange} data={item} key={i} />) : null}
                    {isLoading && records?.data?.length ? <PlaceHolderDummy /> : null}
                    {!isLoading && records?.data?.length < records?.total_records
                        ?
                        <Grid item xs={12}>
                            <Stack alignItems="center" justifyContent="center" >
                                <Button
                                    variant="contained"
                                    onClick={() => setPage(prev => prev + 1)}
                                    sx={{ px: 10, mt: 2 }}
                                >
                                    Load More
                                </Button>
                            </Stack>
                        </Grid>
                        : null
                    }
                </Grid>
            </Container>
        </>
    );
}

export default DashReviewPanel;

const styles = {
    title: {
        fontSize: { md: 28, xs: 22 },
        mt: 2,
    },
    subTitle: {
        fontSize: { md: 26, xs: 20 },
        mt: 2,
    },
    text: {
        fontSize: 14,
        color: "#A6A6A6",
    },
    formControlLabel: {
        ".MuiFormControlLabel-root": {
            margin: 0,
            width: "100%",
            justifyContent: "space-between"
        },
    }
}