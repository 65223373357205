import { createTheme } from "@mui/material/styles";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";

// global date format
export const dateFormat = "DD-MM-YYYY";

// global dateTime format
export const dateTimeFormat = "DD-MM-YYYY HH:mm A";

// token key
export const accessToken="@ParloursAccessToken";

// mui theme settings
export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Lato", "Parisienne"].join(","),
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
    Bold: 700,
    ExtraBold: 900,
  },
  palette: {
    primary: {
      main: "#880808",
    },
    secondary: {
      main: "#000000",
      dimLight: "#F3F3F3",
      light: "#FAFAFA",
    },
    tretiary: {
      main: "#FFFFFF",
    },
    subtext: {
      main: "#585858",
      white: "#FAFAFA",
    },
    error: {
      main: "#EA0C0C", // Modify the error color here
    },
    success: {
      main: "#2CBC4C", // Modify the success color here
    },
    warning: {
      main: "#FABF35", // Modify the warning color here
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

// primary banner on home screen settings
export const PrimaryCarouselSetting = {
  dots: false,
  arrow: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  // fade: true,
  autoplay: true,
  speed: 600,
  autoplaySpeed: 2000,
};

// client review slider settings
export const ReviewSliderSetting = {
  dots: true,
  arrow: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 600,
  autoplaySpeed: 2000,
  appendDots: (dots) => {
    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
  },
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
