import React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DropDown } from "../../../components";
import {
  Checkbox,
  FormControlLabel,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import styled from "@emotion/styled";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    padding: "5px 14px",
    background: "#FCFCFC",
    border: "1px solid #C1C1C1",
    borderRadius: "5px",
    marginRight: "5px",
  },
}));

const ParlourFilter = ({
  callBack = (e) => {},
  setFilterState = (value) => {},
  filterState,
}) => {
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <DropDown
        endIcon={<FilterAltIcon />}
        btnText="Filters"
        variant="contained"
        menuStyles={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
          },
        }}
      >
        <MenuItem sx={styles.menuSx} dense>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={filterState.byDay}
                onChange={handleCheckboxChange}
                name="byDay"
              />
            }
            label="By Day"
          />
        </MenuItem>
        <MenuItem sx={styles.menuSx} dense>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={filterState.byRating}
                onChange={handleCheckboxChange}
                name="byRating"
              />
            }
            label="By Rating"
          />
        </MenuItem>
        <MenuItem sx={styles.menuSx} dense>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={filterState.byCity}
                onChange={handleCheckboxChange}
                name="byCity"
              />
            }
            label="By City"
          />
        </MenuItem>
        <MenuItem sx={styles.menuSx} dense>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Select
                size="small"
                value={filterState.selectedDistance}
                onChange={handleSelectChange}
                name="selectedDistance"
                input={<BootstrapInput />}
              >
                <MenuItem value={10}>10km</MenuItem>
                <MenuItem value={20}>20km</MenuItem>
                <MenuItem value={30}>30km</MenuItem>
                <MenuItem value={40}>40km</MenuItem>
              </Select>
            }
            label="By Distance"
          />
        </MenuItem>
        <MenuItem sx={styles.menuSx} dense>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Select
                size="small"
                value={filterState.selectedPrice}
                onChange={handleSelectChange}
                name="selectedPrice"
                input={<BootstrapInput />}
              >
                <MenuItem value={10}>10$</MenuItem>
                <MenuItem value={20}>20$</MenuItem>
                <MenuItem value={30}>30$</MenuItem>
                <MenuItem value={40}>40$</MenuItem>
              </Select>
            }
            label="By Price"
          />
        </MenuItem>
      </DropDown>
    </div>
  );
};

export default ParlourFilter;

const styles = {
  menuSx: {
    width: 320,
    maxWidth: "100%",
    ".MuiFormControlLabel-root": {
      margin: 0,
      width: "100%",
      justifyContent: "space-between",
    },
  },
};
