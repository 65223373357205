import { useState, useEffect } from 'react';

const useLocation = (onlyPermission = false) => {
  const [location, setLocation] = useState(null);
  const [hasPermission, setHasPermission] = useState('');

  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setLocation({ lat: latitude, lng: longitude });
  };

  const handleError = (error) => {
    setLocation(null);
    console.error(`Error getting location: ${error.message}`);
  };

  useEffect(() => {
    let isMounted = true;

    const requestLocationPermission = () => {
      if (navigator.permissions) {
        navigator.permissions
          .query({ name: 'geolocation' })
          .then((permissionStatus) => {
            setHasPermission(permissionStatus.state === 'granted' || permissionStatus.state === 'prompt');
            if (isMounted && !onlyPermission) {
              if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
                navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
                  enableHighAccuracy: true,
                });
              } else {
                console.error('Location permission denied.');
              }
            }
          })
          .catch((error) => {
            console.error(`Error requesting location permission: ${error.message}`);
          });
      } else if (navigator.geolocation && !onlyPermission) {
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
          enableHighAccuracy: true,
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    requestLocationPermission();

    return () => {
      isMounted = false;
    };
  }, []);

  return [location, hasPermission];
};

export default useLocation;