import { getAuth, signInWithPopup, FacebookAuthProvider } from "@firebase/auth";
import fire from "../firebase";

const facebookLogin = (e) => {
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({
        auth_type: 'rerequest', // Add the auth_type parameter
    });
    const auth = getAuth(fire);
    return new Promise(async (resolve, reject) => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // const user = result.user;
                const credential = FacebookAuthProvider.credentialFromResult(result);
                resolve(credential.accessToken);
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);
                reject(error)
            });
    })
}

export default facebookLogin;