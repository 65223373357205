import { Button, CircularProgress } from '@mui/material';
import React from 'react';

function LoadingButton({ loading = false, loaderStyle = {}, startIcon: propsIcon = null, children, ...props }) {
    return (
        <Button
            {...props}
            startIcon={loading ? <CircularProgress size={18} sx={{ color: "#fff", ...loaderStyle }} /> : propsIcon}
        >
            {children}
        </Button>
    );
}

export default LoadingButton;