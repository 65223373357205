import React from 'react';
import { Skeleton, Stack, Box } from '@mui/material';

function TestimonialsPlaceholder() {
    return <Box sx={{ backgroundColor: "#fff", py: 8, px: 4 }}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={6}>
            <Box sx={{ width: "20%" }}>
                <Skeleton variant="rounded" sx={{ width: "100%", height: "80px" }} />
            </Box>
            <Box sx={{ flex: 1 }}>
                <Stack spacing={1}>
                    <Skeleton variant="rounded" sx={{ width: "150px", height: "25px" }} />
                    <Skeleton variant="rounded" sx={{ width: "100%", height: "120px" }} />
                    <Skeleton variant="rounded" sx={{ width: "60px", height: "10px" }} />
                </Stack>
            </Box>
        </Stack>
    </Box>
}

export default TestimonialsPlaceholder;