import React, { useState, useEffect } from "react";
import { Grid, Typography, Container, Stack, MenuItem, Button, Box } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useDispatch, useSelector } from "react-redux";

import {
    DashServiceCard,
    DashboardHeader,
    DropDown,
} from "../../../components";
import { packagesData } from "./data";
import usePageTitle from "../../../hooks/use-page-title";
import { DashServiceCardPlaceholder } from "../../../placeholders";
import apiManager from "../../../services/api-manager";
import { handleLoader, setToast, setUser } from "../../../store/reducer";

function DashServices() {
    usePageTitle("Services");
    const [records, setRecords] = useState(null);
    const [activeServices, setActiveServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterState, setFilterState] = useState("all");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.appReducer);

    useEffect(() => {
        async function init() {
            try {
                setIsLoading(true);
                let { data } = await apiManager({ path: `my-admin/services?page=${page}&per_page=${perPage}status=active`, method: "get" });
                if (!records?.data.length) {
                    setRecords(data?.data);
                } else {
                    setRecords(prev => ({ ...prev, data: prev?.data.concat(data?.data?.data) }));
                }
            } catch (error) {
                dispatch(setToast({ type: "error", message: error?.response?.data?.message }))
            } finally {
                setIsLoading(false);
            }
        }
        if (filterState === "active" && !activeServices.length) {
            getMyActiveServices();
        } else if (!records) {
            init();
        }
    }, [filterState, perPage, page]);

    const handleServicesActivation = async (item) => {
        const { _id: id } = item;
        dispatch(handleLoader(true));
        let index = user.services.indexOf(id);
        let _fd = {}
        if (index === -1) {
            _fd.activateServices = [id];
        } else {
            _fd.deactivateServices = [id];
        }
        try {
            let { data } = await apiManager({
                method: "post", path: "parlour/service/add-or-remove-service", params: _fd
            })
            dispatch(setToast({ type: "success", message: data?.message }));
            if (index === -1) {
                setActiveServices(prev => ([...prev, item]))
            } else {
                setActiveServices(prev => {
                    let arr = prev.map(item => {
                        if (item._id !== id) {
                            return item;
                        }
                    })
                    return arr;
                })
            }
            dispatch(setUser(data?.parlour));
        } catch (error) {
            dispatch(setToast({ type: "error", message: error?.response?.data?.message }))
        } finally {
            dispatch(handleLoader(false));
        }
    }

    async function getMyActiveServices() {
        try {
            setIsLoading(true);
            let { data } = await apiManager({ path: `parlour/services/get-active`, method: "get" });
            setActiveServices(data?.data);
        } catch (error) {
            dispatch(setToast({ type: "error", message: error?.response?.data?.message }))
        } finally {
            setIsLoading(false);
        }
    }

    const PlaceHolderDummy = () => {
        return Array(5)
            .fill("")
            .map((_, i) => (
                <Grid item key={i} xl={2.4} lg={3} md={4} sm={6} xs={12}>
                    <DashServiceCardPlaceholder data={packagesData[0]} />
                </Grid>
            ))
    }

    return (
        <>
            <DashboardHeader />
            <Container maxWidth="xxl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={styles.title}>
                            {filterState === "active" ? "Active Services" : filterState === "un_active" ? "Unactive Services" : "All Services"}
                        </Typography>
                    </Grid>

                    < Grid container spacing={2} mt={1}>
                        <Grid item xs={12} display="flex" justifyContent="flex-end">
                            <DropDown
                                btnText="Filter"
                                variant="contained"
                                endIcon={<FilterAltIcon />}
                            >
                                <MenuItem
                                    onClick={() => setFilterState("all")}
                                    selected={filterState === "all"}
                                >
                                    All Services
                                </MenuItem>
                                <MenuItem
                                    onClick={() => setFilterState("active")}
                                    selected={filterState === "active"}
                                >
                                    Active Services
                                </MenuItem>
                                <MenuItem
                                    onClick={() => setFilterState("un_active")}
                                    selected={filterState === "un_active"}
                                >
                                    UnActive Services
                                </MenuItem>
                            </DropDown>
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: filterState !== "active" ? "block" : "none"
                    }}
                >
                    <Grid container spacing={2} mt={3} mb={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} my={2}>
                                {isLoading && !records?.data?.length
                                    ? <PlaceHolderDummy />
                                    : null}
                                {records?.data?.length ? records?.data?.map((data, i) => {
                                    const isActive = user.services.indexOf(data?._id) !== -1;
                                    if (filterState === "un_active" && isActive) {
                                        return <></>
                                    }
                                    return (
                                        <Grid
                                            item key={i} xl={2.4} lg={3} md={4} sm={6} xs={12}>
                                            <DashServiceCard
                                                data={data}
                                                handleClick={handleServicesActivation}
                                                isActive={isActive}
                                            />
                                        </Grid>
                                    );
                                }) : null}
                                {isLoading && records?.data?.length
                                    ? <PlaceHolderDummy />
                                    : null}
                            </Grid>
                            {!isLoading && records?.data?.length < records?.total_records
                                ?
                                <Grid item xs={12}>
                                    <Stack alignItems="center" justifyContent="center" >
                                        <Button
                                            variant="contained"
                                            onClick={() => setPage(prev => prev + 1)}
                                            sx={{ px: 10, mt: 2 }}
                                        >
                                            Load More
                                        </Button>
                                    </Stack>
                                </Grid>
                                : null
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: filterState === "active" ? "block" : "none"
                    }}
                >
                    <Grid container spacing={2} mt={3} mb={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} my={2}>
                                {isLoading && !activeServices?.length
                                    ? <PlaceHolderDummy />
                                    : null}
                                {activeServices?.length ? activeServices?.map((data, i) => {
                                    const isActive = user.services.indexOf(data?._id) !== -1;
                                    return (
                                        <Grid
                                            item key={i} xl={2.4} lg={3} md={4} sm={6} xs={12}>
                                            <DashServiceCard
                                                data={data}
                                                handleClick={handleServicesActivation}
                                                isActive={isActive}
                                            />
                                        </Grid>
                                    );
                                }) : null}
                                {isLoading && activeServices?.length
                                    ? <PlaceHolderDummy />
                                    : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container >
        </>
    );
}

export default DashServices;

const styles = {
    title: {
        fontSize: { md: 28, xs: 22 },
        mt: 2,
    },
    subTitle: {
        fontSize: { md: 26, xs: 20 },
        mt: 2,
    },
    text: {
        fontSize: 14,
        color: "#A6A6A6",
    },
    formControlLabel: {
        ".MuiFormControlLabel-root": {
            margin: 0,
            width: "100%",
            justifyContent: "space-between",
        },
    },
};
