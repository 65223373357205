import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  Stack,
  Skeleton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import apiManager from "../../../services/api-manager";
import { DashboardHeader, TeamCard } from "../../../components";
import { handleLoader, setToast } from "../../../store/reducer";
import usePageTitle from "../../../hooks/use-page-title";
import { TeamCardPlaceholder } from "../../../placeholders";

const DashTeamListing = () => {
  usePageTitle("Teams");
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      setIsLoading(true);
      let { data } = await apiManager({ path: "parlour/teams/get-all", method: "get" });
      setRecords(data?.data);
    } catch (error) {
      dispatch(setToast({ type: "error", message: error?.response?.data?.message }))
    } finally {
      setIsLoading(false);
    }
  }
  const deleteMemberFromTeam = async (memberId) => {
    try {
      dispatch(handleLoader(true));
      let { data } = await apiManager({ method: "delete", path: `parlour/teams/${memberId}` });
      dispatch(setToast({ type: "success", message: data?.message }));
      getData();
    } catch (error) {
      dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
    } finally {
      dispatch(handleLoader(false));
    }
  }

  return (
    <div>
      <DashboardHeader />
      <Container maxWidth="xxl" sx={{ mt: "59px" }}>
        <Box>
          <Typography children="Reviews Panel" sx={{
            fontSize: { md: 28, xs: 22 },
            mt: 2,
          }} />
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
        >
          <Box mt={{ sm: 0, xs: 1 }}>
            <Button
              variant="contained"
              onClick={() => navigate("/dashboard/team/add")}
              color="primary"
            >
              Add team member
            </Button>
          </Box>
        </Stack>
        <Grid
          className="profileCards"
          container
          spacing={2}
          sx={{ mt: "38px" }}
        >
          {isLoading ? (
            Array(3)
              .fill("")
              .map((each, i) => {
                return (
                  <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                    <TeamCardPlaceholder />
                  </Grid>
                );
              })
          ) : records.length ? (
            records.map((item, i) => {
              return (
                <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                  <TeamCard data={item} each={item} menu handleRemove={deleteMemberFromTeam} />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#333",
                  textAlign: "center",
                }}
                mt={3}
              >
                No data found. Add your team members.
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* <Stack
          sx={{
            placeItems: "center",
            mt: "39px",
            mb: "29px",
          }}
        >
          <Button
            endIcon={
              <KeyboardArrowDownIcon
                sx={{ fontSize: "2rem!important", color: "black" }}
              />
            }
            sx={{ fontSize: "24px ", color: "black" }}
          >
            Load More
          </Button>
        </Stack> */}
      </Container>
    </div>
  );
};

export default DashTeamListing;
