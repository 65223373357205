import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, Stack, Button } from "@mui/material";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ReviewModal from "./review-modal/review-modal";
import {
  Banner,
  PackagesCard,
  ProfileCard,
  ReviewCard,
} from "../../../components";
import { size } from "../../../assets/font-sizes";
import { ReviewSliderSetting } from "../../../constant";
import apiManager from "../../../services/api-manager";
import { setToast } from "../../../store/reducer";
import { BannerPlaceholder, DashServiceCardPlaceholder, ReviewCardPlaceholder, TeamCardPlaceholder } from "../../../placeholders";

const ParlourPage = () => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [record, setRecord] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector(state => state.appReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      try {
        let { data } = await apiManager({ method: "get", path: `user/parlour/${id}/details` });
        setRecord(data?.data);
      } catch (error) {
        dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, []);

  if (isLoading) {
    return <>...loading screen. please wait</>
  }

  return (
    <div>
      {isLoading && <BannerPlaceholder />}
      <Box className="hero" component="section">
        <Banner
          bgImage={record?.meta?.banner_image?.url}
          logo={record?.meta?.logo?.url}
          title={record?.meta?.title}
          subText={record?.meta?.tagline}
        />
      </Box>

      <Box>
        <Box py={2} sx={{ px: { sm: 4, xs: 2 }, py: 4 }}>
          <Typography
            variant="h2"
            color="initial"
            fontSize={size.subHeading}
            fontWeight={600}
          >
            Meet Our Team
          </Typography>
          <Typography
            variant="body1"
            component="p"
            color="#2F2105CC"
            fontSize={size.text}
            fontWeight={400}
            width={{ lg: "50%", sm: "auto" }}
          >
            Lorem ipsum dolor sit amet consectetur. Tincidunt leo a viverra
            nulla purus ultrices diam. Pulvinar et nunc lectus aliquam hendrerit
            egestas
          </Typography>
          <Box mt={3}>
            <ProfileCard isLoading={isLoading} data={record?.teams} />
          </Box>
        </Box>
        {/* packages cards */}
        <Box sx={{ py: 10, background: "#FAFAFA", px: { sm: 4, xs: 2 } }}>
          <Typography
            variant="h3"
            color="#454546"
            textAlign={"center"}
            fontSize={size.subHeading}
            fontWeight={400}
          >
            Our Services
          </Typography>
          <Typography
            variant="body1"
            color="#2F2105CC"
            component={"p"}
            textAlign={"center"}
            fontSize={{ sm: 18, xs: 8 }}
            fontWeight={400}
          >
            Lorem ipsum dolor sit amet consectetur. Tincidunt leo a viverra
            nulla purus ultrices diam. Pulvinar <br /> et nunc lectus aliquam
            hendrerit egestas
          </Typography>
          <Grid container spacing={3} my={2}>
            {isLoading && Array(5).fill("").map((_, i) => <Grid item key={i} xl={2.4} lg={3} md={4} sm={6} xs={12} children={<DashServiceCardPlaceholder />} />)}
            {record?.services?.length && record?.services.map((data, i) => (
              <Grid item key={i} xl={2.4} lg={3} md={4} sm={6} xs={12}>
                <PackagesCard data={data} />
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* packages cards */}

        {/* review cards */}
        <Box py={10} sx={{ px: { sm: 4, xs: 2 } }}>
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent={"space-between"}
          >
            <Stack>
              <Typography
                variant="h5"
                color="initial"
                fontWeight={500}
                fontSize={{ sm: 24, xs: 20 }}
                textAlign={{ sm: "left", xs: "center" }}
              >
                Our Customer Feedback
              </Typography>
              <Typography
                variant="p"
                color="subtext.main"
                fontWeight={400}
                fontSize={{ sm: 18, xs: 16 }}
                textAlign={{ sm: "left", xs: "center" }}
              >
                Don’t take our word for it. Trust our customers
              </Typography>
            </Stack>
            {user?.role === "user" && <Button
              variant="text"
              sx={{
                color: "black",
                ":hover": {
                  background: "white",
                },
              }}
              onClick={() => setOpenModal(!openModal)}
            >
              Add a Review
            </Button>}
          </Stack>

          {record?.reviews?.length && <Box mt={5} className="review-slider">
            <Slider {...ReviewSliderSetting}>
              {isLoading && Array(8).fill().map((_, i) => (
                <ReviewCardPlaceholder key={i} />
              ))}
              {record?.reviews.map((item, i) => (
                <ReviewCard key={i} data={item} />
              ))}
            </Slider>
          </Box>}
        </Box>

        {/* review cards */}

        {/* review modal */}
        <ReviewModal
          open={openModal}
          parlour_id={record?._id}
          handleClose={() => setOpenModal(!openModal)}
        />
        {/* review modal */}
      </Box>
    </div>
  );
};

export default ParlourPage;
