import React from "react";
import { Grid, Stack, Box, Skeleton } from "@mui/material";

function DashReviewCardPlaceHolder() {
  return (
    <Grid item sm={6} xs={12}>
      <Box
        component={Stack}
        border="1px solid #ccc"
        borderRadius={3}
        p={2}
        maxWidth="100%"
        flexDirection="row"
        alignItems="center"
      >
        <Skeleton
          variant="circular"
          sx={{
            width: 85,
            height: 85,
          }}
        />
        <Box sx={{ flex: 1, pl: 2 }}>
          <Skeleton variant="rounded" sx={{ width: "30%", height: 15 }} />
          <Skeleton
            variant="rounded"
            sx={{ width: "100%", mt: 1, height: 100 }}
          />
          <Stack
            direction="row"
            mt={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Skeleton variant="rounded" sx={{ width: 55, height: 15 }} />
            <Skeleton variant="rounded" sx={{ width: 100, height: 35 }} />
          </Stack>
        </Box>
      </Box>
    </Grid>
  );
}

export default DashReviewCardPlaceHolder;
