import { Skeleton,Stack ,Box} from "@mui/material";

const TeamCardPlaceholder = () => {
    return (
      <Box>
        <Skeleton width="100" height={220} variant="rounded" />
        <Stack spacing={1} mt={3}>
          <Skeleton width="70%" height={20} variant="rounded" />
          <Skeleton width="30%" height={10} variant="rounded" />
          <Skeleton width="100%" height={70} variant="rounded" />
        </Stack>
        <Stack spacing={1} direction="row" mt={2}>
          <Skeleton width={25} height={25} variant="rounded" />
          <Skeleton width={25} height={25} variant="rounded" />
          <Skeleton width={25} height={25} variant="rounded" />
          <Skeleton width={25} height={25} variant="rounded" />
        </Stack>
      </Box>
    );
  };
export default TeamCardPlaceholder