import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  ListItemButton,
  ListItemText,
  MenuList,
  Paper,
  Slide,
  Stack,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, signOut } from "@firebase/auth";

import { Logo } from "../../assets";
import LoginForm from "./login-form/login-form";
import RegistrationForm from "./registration-form/registration-form";
import DropDown from "../dropdown/dropdown";
import { handleLoader, logoutUser, setToast } from "../../store/reducer";
import apiManager from "../../services/api-manager";
import { accessToken } from "../../constant";
import fire from "../../firebase";

function DrawerAppBar() {
  const [colorChange, setColorChange] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { user, isLogged } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    function updatePosition() {
      if (window.scrollY > window.innerHeight) {
        setColorChange(true);
      } else {
        setColorChange(false);
      }
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  const logOut = async () => {
    try {
      dispatch(handleLoader(true));
      await apiManager({ method: "post", path: "logout" });
      const auth = getAuth(fire);
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          console.log("there res");
        })
        .catch((error) => {
          console.log(error);
          // An error happened.
        });
      localStorage.removeItem(accessToken);
      dispatch(logoutUser());
    } catch (error) {
      dispatch(setToast({ type: "error", message: "Error occurred!" }));
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          zIndex: 1,
          top: "50%",
          borderRadius: "100vw",
          left: 5,
          display: { md: "none", sm: "block" },
        }}
        component={Paper}
        elevation={6}
      >
        <IconButton
          aria-label="dialog-btn"
          onClick={() => setOpenDialog(!openDialog)}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{
            backgroundColor: colorChange
              ? "rgba( 0, 0, 0, 0.25 )"
              : "transparent",
            boxShadow: colorChange && "0 8px 32px 0 rgba( 0, 0, 0, 0.50 )",
            backdropFilter: colorChange && "blur(20px)",
            p: 1,
          }}
          elevation={0}
        >
          <Toolbar>
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item lg={2} md={3} align="left">
                <Box className="logo-wrapper">
                  <NavLink to={"/"}>
                    <Box
                      component={"img"}
                      src={Logo}
                      alt="logo"
                      sx={{ maxWidth: "100%" }}
                    />
                  </NavLink>
                </Box>
              </Grid>
              <Grid
                item
                lg={8}
                md={6}
                sx={{ display: { md: "block", xs: "none" } }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                    },
                  }}
                  component="ul"
                  className="nav-ul"
                >
                  {navItems.map((item, i) => (
                    <Box
                      key={i}
                      component="li"
                      className="inner-wrapper wrapper-16"
                    >
                      <NavLink
                        key={i}
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link nav-active link hover-16"
                            : "nav-link link hover-16"
                        }
                        to={item.path}
                      >
                        {item.label}
                      </NavLink>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid
                item
                lg={2}
                md={3}
                sx={{ display: { md: "block", xs: "none" } }}
                align={"center"}
              >
                <Box sx={{ display: "inline-block" }}>
                  {isLogged ? (
                    user?.role === "user" ? (
                      <DropDown
                        btnText={user?.name}
                        variant="text"
                        color="tretiary"
                        startIcon={<Avatar src={user?.avatar}>{""}</Avatar>}
                      >
                        <Box sx={{ minWidth: 230 }}>
                          <ListItemButton onClick={() => {}}>
                            <ListItemIcon>
                              <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary="Profile" />
                          </ListItemButton>
                          <ListItemButton onClick={logOut}>
                            <ListItemIcon>
                              <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                          </ListItemButton>
                        </Box>
                      </DropDown>
                    ) : (
                      <Button
                        onClick={() => navigate("/dashboard")}
                        variant="contained"
                      >
                        Dashboard
                      </Button>
                    )
                  ) : (
                    <Stack direction="row" gap={2}>
                      <DropDown
                        btnText="Registration"
                        variant="text"
                        color="tretiary"
                        menuStyles={{ mt: 1 }}
                      >
                        <RegistrationForm />
                      </DropDown>

                      <DropDown
                        btnText="SignIn"
                        variant="contained"
                        menuStyles={{ mt: 1 }}
                      >
                        <LoginForm />
                      </DropDown>
                    </Stack>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <DailogNav
        open={openDialog}
        handleClose={() => setOpenDialog(!openDialog)}
        isLogged={isLogged}
        user={user}
        logOut={logOut}
      />
    </>
  );
}

const navItems = [
  { label: "Blogs", path: "/blog" },
  { label: "Parlours", path: "/parlours" },
  { label: "Contact Us", path: "/contact-us" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DailogNav = ({ open, handleClose, isLogged, user, logOut }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [screenType, setScreenType] = useState("nav");
  
  useEffect(() => {
    if (isLogged) {
      setScreenType("nav");
    }
  }, [isLogged]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {screenType !== "nav" && (
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Box
              sx={{
                position: "absolute",
                right: "0",
                top: 8,
                left: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setScreenType("nav")}
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
          </DialogTitle>
        )}
        <DialogContent sx={{ padding: "20px 20px;" }}>
          {screenType === "nav" && (
            <Paper sx={{ width: 320, maxWidth: "100%" }} elevation={0}>
              <List disablePadding>
                {navItems.map((each, i) => (
                  <ListItemButton
                    key={i}
                    selected={location.pathname === each.path}
                    onClick={() => {
                      navigate(each.path);
                      handleClose();
                    }}
                  >
                    <ListItemText sx={{ textAlign: "center" }}>
                      {each.label}
                    </ListItemText>
                  </ListItemButton>
                ))}
              </List>
              {isLogged ? (
                user?.role === "user" ? (
                  <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        "& .MuiAccordionSummary-content": {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "5",
                        },
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={2}
                        justifyContent="center"
                      >
                        <Avatar src={user?.avatar} />
                        <Typography variant="body1" color="initial">
                          {user?.name}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List disablePadding>
                        <ListItemButton
                          onClick={() => {}}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <ListItemIcon sx={{ minWidth: "33px" }}>
                              <PersonIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary="Profile"
                              sx={{ display: "inline-block" }}
                            />
                          </Stack>
                        </ListItemButton>
                        <ListItemButton
                          onClick={logOut}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <ListItemIcon sx={{ minWidth: "33px" }}>
                              <LogoutIcon />
                            </ListItemIcon>

                            <ListItemText
                              primary="Logout"
                              sx={{ display: "inline-block" }}
                            />
                          </Stack>
                        </ListItemButton>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <Stack
                    direction="row"
                    gap={2}
                    align="center"
                    justifyContent={"center"}
                    mt={2}
                  >
                    <Button
                      onClick={() => navigate("/dashboard")}
                      variant="contained"
                    >
                      Dashboard
                    </Button>
                  </Stack>
                )
              ) : (
                <Stack
                  direction="row"
                  gap={2}
                  align="center"
                  justifyContent={"center"}
                  mt={2}
                >
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setScreenType("REG")}
                  >
                    Registration
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setScreenType("SIGNIN")}
                  >
                    SignIn
                  </Button>
                </Stack>
              )}
            </Paper>
          )}

          {screenType === "SIGNIN" && <LoginForm />}
          {screenType === "REG" && <RegistrationForm />}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DrawerAppBar;
