import React from "react";
import {
  Box,
  Button,
  Chip,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ParlourDisplayCard = ({ data, handelPress }) => {
  return (
    <Paper
      sx={{
        width: "100%",
        minHeight: "385px",
        left: "72px",
        top: "764px",
        background: "#ffff",
        p: "13px",
        transition: "transform .3s ease-out",
        borderRadius: "8px",
        ":hover": {
          transform: "translate(0% ,-1%)",
          filter: "drop-shadow(0px 6px 27px rgba(0, 0, 0, 0.09))",
        },
      }}
      elevation={0}
      onClick={handelPress}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src={data?.meta?.banner_image?.url}
          sx={{
            borderRadius: "8px",
            width: "100%",
            minWidth: "200px",
            maxHeight: "300px",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "71%",
            right: 13,
          }}
        >
          <Chip
            icon={<LocationOnIcon color="primary" />}
            label="1 Km"
            sx={{ background: "white" }}
          />
        </Box>
      </Box>
      <Box mt={"19px"}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            color="#2F2105"
            fontSize={{ md: 18, sm: 15, xs: 14 }}
            fontWeight={600}
          >
            {data?.meta?.title}
          </Typography>
          <Box>
            <Box
              component="img"
              src={data?.meta?.logo?.url}
              width={{ md: "70px", xs: "60px" }}
            />
          </Box>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          mt={"5px"}
        >
          <Typography
            variant="subtitle2"
            color="#D68F5C"
            fontSize={{ md: 14, sm: 12, xs: 9 }}
            fontWeight={600}
          >
            Owner: Grace Hall
          </Typography>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            gap={0.2}
          >
            <Typography
              variant="subtitle2"
              color="#2F2105"
              fontSize={{ sm: 14, xs: 10 }}
              fontWeight={700}
            >
              {data?.meta?.total_rating}
            </Typography>
            <StarIcon sx={{ color: "#FFD057", fontSize: "1rem" }} />
            <Typography
              variant="subtitle2"
              color="#7E7D7A"
              fontSize={{ md: 12, sm: 11, xs: 10 }}
              fontWeight={500}
              >
              {data?.meta?.total_reviews} Reviews
            </Typography>
          </Stack>
        </Stack>

        <Typography variant="body1" color="#7E7D7A" fontSize={12} mt={"15px"}>
          {data?.meta?.description}
        </Typography>
        <Button
          startIcon={<RemoveRedEyeIcon />}
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handelPress}
        >
          View
        </Button>
      </Box>
    </Paper>
  );
};

export default ParlourDisplayCard;
