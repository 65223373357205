import React, { useState } from "react";
import { Button, IconButton, Menu } from "@mui/material";

function DropDown({ btnText, children, icon, useIconBtn = false, menuStyles, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {useIconBtn ?
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : "false"}
          {...props}
          onClick={handleClick}
        >
          {icon}
        </IconButton>
        : <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : "false"}
          {...props}
          onClick={handleClick}
        >
          {btnText}
        </Button>}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={menuStyles}
      >
        {children}
      </Menu>
    </div>
  );
}

export default DropDown;
