export const size = {
    main: {
        xl: 68,
        lg: "0.8rem",
        md: "0.64rem",
        sm: "0.512rem",
        xs: "0.512rem"
    },
    subHeading: {
        xl: "35.00px",
        lg: "28.00px",
        md: "22.40px",
        sm: "22.40px",
        xs: "22.40px"
    },
    text: {
        sm: "1.1rem",
        xs: "1rem"
    }
}