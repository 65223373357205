import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import React from "react";
import { PackagesCardDummySvg } from "../../assets";

const PackagesCard = ({ data }) => {
  return (
    <div>
      <Card
        sx={{
          maxWidth: { md: "320px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "350px",
          ":hover": {
            boxShadow: "0px 3px 40px rgba(0, 0, 0, 0.07)",
          },
        }}
        elevation={0}
      >
        <Stack
          sx={{
            width: "100px",
            height: "100px",
            background: "#F1F1F14F",
            justifyContent: "center",
            placeItems: "center",
            borderRadius: "100vw ",
          }}
        >
          <CardMedia
            component="img"
            image={data?.icon?.url}
            alt="PackagesCardDummySvg"
          />
        </Stack>
        <CardContent>
          <Typography
            variant="h4"
            fontSize={{ sm: 25, xs: 20 }}
            fontWeight={500}
            color="initial"
            textAlign={"center"}
          >
            {" "}
            {data?.title}
          </Typography>
          <Typography
            variant="body1"
            component={"p"}
            fontSize={"1rem"}
            fontWeight={400}
            color="#7E7D7A"
            align="center"
            my={2}
          >
            {data.description.length <= 100
              ? data.description
              : data.description.substr(0, 100) + "..."}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default PackagesCard;
