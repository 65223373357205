import { blogBanner, post1 } from "../../assets"

export const postJson={
    bannerImage:blogBanner,
    bannerTitle:"Here are some top tips to enhance your wedding Look",
    bannerSubTitle:"Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    heading:"Here are some top  tips to enahance your wedding look",
    author:"Amir Zeb",
    published:"May 19 2023",
    description:"Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodales purus sed habitant viverra purus egestas suspendisse dictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus. Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodaledictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus.",
    sideImg:post1,
    videoUrl:"https://youtu.be/UwXQORj2Nzc",
}