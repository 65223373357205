import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { PackagesCardDummySvg } from "../../assets";

const DashServiceCardPlaceholder = ({ data, status = true }) => {
  return (
    <div>
      <Card
        sx={{
          maxWidth: { md: "320px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "350px",
          ":hover": {
            boxShadow: "0px 3px 40px rgba(0, 0, 0, 0.07)",
          },
          py: 2,
        }}
        elevation={0}
      >
        <Skeleton variant="circular" width={100} height={100} />

        <CardContent>
          <Typography
            variant="h4"
            fontSize={{ sm: 25, xs: 20 }}
            fontWeight={500}
            color="initial"
            textAlign={"center"}
          >
            <Skeleton width={"250px"} />
          </Typography>
          <Typography
            variant="body1"
            component={"p"}
            fontSize={"1rem"}
            fontWeight={400}
            color="#7E7D7A"
            align="center"
            my={2}
          >
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Typography>
        </CardContent>
        <CardActions>
          <Stack sx={{ width: "100%" }}>
            <Skeleton width={200} height={50} />
          </Stack>
        </CardActions>
      </Card>
    </div>
  );
};

export default DashServiceCardPlaceholder;
