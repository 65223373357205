import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid, Stack, Typography } from "@mui/material";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import TestimonialsCarousel from "../../components/testimonials-carousel/testimonials-carousel";
import { Banner, ParlourDisplayCard } from "../../components";
import { size } from "../../assets/font-sizes";
import { PrimaryCarouselSetting } from "../../constant";
import ParlourFilter from "./parlour-filter/parlour-filter";
import { setToast } from "../../store/reducer";
import apiManager from "../../services/api-manager";
import usePageTitle from "../../hooks/use-page-title";
import { BannerPlaceholder, ParlourDisplayCardPlaceholder } from "../../placeholders";

function ParlourHome() {
  usePageTitle("Parlours");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [topRated, setTopRated] = useState([]);
  const [nearParlours, setNearParlours] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [filterState, setFilterState] = useState({
    byDay: false,
    byRating: false,
    byCity: false,
    selectedDistance: 10,
    selectedPrice: 10,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      try {
        let { data } = await apiManager({ method: "get", path: "user/parlour/get-home-data" });
        setTopRated(data?.data?.top_rated_parlours);
        setNearParlours(data?.data?.near_parlours);
        setTestimonials(data?.data?.random_reviews);
      } catch (error) {
        dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, []);

  if (isLoading) {
    return <>...loading screen. please wait</>
  }

  return (
    <Box>
      {isLoading && <BannerPlaceholder />}
      {topRated?.length && <Box className="hero" component="section">
        <Slider {...PrimaryCarouselSetting}>
          {topRated.map(item => {
            return <Banner
              bgImage={item?.meta?.banner_image?.url}
              logo={item?.meta?.logo?.url}
              title={item?.meta?.title}
              subText={item?.meta?.tagline}
              buttonText="Visit"
              onClick={() => {
                navigate(`/parlours/${item?._id}`)
              }}
            />
          })}
        </Slider>
      </Box>}
      <Box>
        <Box>
          <Box sx={{ background: "white", px: { sm: 4, xs: 2 }, py: 4 }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              mb={2}
              alignItems="center"
            >
              <Typography
                variant="h2"
                color="initial"
                fontWeight={400}
                fontSize={size.subHeading}
                fontFamily={"Parisienne"}
              >
                Near You
              </Typography>
              <ParlourFilter
                setFilterState={(value) => setFilterState(value)}
                filterState={filterState}
              />
            </Stack>
            <Grid container spacing={{ sm: 4, xs: 2 }}>
              {isLoading
                ? Array(4)
                  .fill("_")
                  .map((_, i) => (
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={i}>
                      <ParlourDisplayCardPlaceholder
                        handelPress={() => { }}
                      />
                    </Grid>
                  ))
                : null}
              {nearParlours.length &&
                nearParlours.map((item, i) => (
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={i}>
                    <ParlourDisplayCard
                      data={item}
                      handelPress={() => navigate(`/parlours/${item?._id}`)}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </Box>

        {/* Testimonials */}
        <Box sx={{ pt: 10, pb: 10, backgroundColor: "secondary.light" }}>
          <Typography
            variant="h3"
            color="initial"
            textAlign={"center"}
            fontSize={35}
            fontWeight={400}
            fontFamily={"Parisienne"}
          >
            Testimonials
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            component={"p"}
            textAlign={"center"}
            fontSize={{ sm: 18, xs: 8 }}
            fontWeight={400}
          >
            Lorem ipsum dolor sit amet consectetur. Tincidunt leo a viverra
            nulla purus ultrices diam. Pulvinar <br /> et nunc lectus aliquam
            hendrerit egestas
          </Typography>
          {testimonials.length && <Box width={"100%"} mt={5}>
            <TestimonialsCarousel isLoading={isLoading} data={testimonials} />
          </Box>}
        </Box>
        {/* Testimonials */}
      </Box>
    </Box>
  );
}

export default ParlourHome;