import React, { useEffect, useState } from 'react';
import { Grid, Typography, Container, Box, Stack, Button } from '@mui/material';

import { DashboardHeader, InputField, LoadingButton, PasswordField } from '../../../components';
import usePageTitle from '../../../hooks/use-page-title';
import { useDispatch, useSelector } from 'react-redux';
import { setToast, setUser } from '../../../store/reducer';
import apiManager from '../../../services/api-manager';
import { async } from 'q';

function DashProfile() {
    usePageTitle("Profile");
    const { user } = useSelector(state => state.appReducer);
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [IsLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormData({
            password: "",
            email: user?.email || "",
            full_name: user?.full_name || "",
            phone: user?.phone || "",
        });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    const handleForm = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            let { data } = await apiManager({ method: "post", path: "parlour/update-details", params: formData, header: { 'Content-Type': 'multipart/form-data' } });
            dispatch(setToast({ type: "success", message: data?.message }));
            dispatch(setUser(data?.data));
        } catch (error) {
            if (error?.response?.status === 422) {
                setFormErrors(error?.response?.data?.error)
            }
            dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <DashboardHeader />
            <Container maxWidth="xxl" >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography children="Personal Profile" sx={styles.subTitle} />
                        <Typography children="Lorem ipsum dolor sit amet consectetur. Nullam bibendum." sx={styles.text} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" onSubmit={handleForm} maxWidth="850px">
                            <Stack direction="column" spacing={2} mb={4} mt={2}>
                                <InputField placeholder="Full name" labelTop="Full Name" br={1} value={formData?.full_name} error={formErrors?.full_name} name="full_name" onChange={handleInputChange} />
                                <InputField placeholder="Phone Number" labelTop="Phone Number" br={1} value={formData?.phone} error={formErrors?.phone} name="phone" onChange={handleInputChange} />
                                <InputField placeholder="john@gmail.com" labelTop="Email" br={1} value={formData?.email} error={formErrors?.email} type="Email" name="email" onChange={handleInputChange} />
                                <PasswordField placeholder="******" labelTop="Current Password" value={formData?.password} error={formErrors?.password} name="password" helperText="Password should be 6 digits long." onChange={handleInputChange} sx={{ borderRadius: "0 !important" }} />
                                <PasswordField placeholder="******" labelTop="New Password" value={formData?.new_password} error={formErrors?.new_password} name="new_password" helperText="Password should be 6 digits long." onChange={handleInputChange} sx={{ borderRadius: "0 !important" }} />
                                {/* <SelectBox value={formData.status} labelTop="Status" br={1} name="status" items={[{ label: "Active", value: "true" }, { label: "Disabled", value: "false" }]} onChange={handleInputChange} /> */}
                            </Stack>
                            <LoadingButton disabled={IsLoading} loading={IsLoading} variant="contained" sx={{ px: 10 }} type="submit">
                                Save
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default DashProfile;

const styles = {
    subTitle: {
        fontSize: { md: 26, xs: 20 },
        mt: 2,
    },
    text: {
        fontSize: 14,
        color: "#A6A6A6",
    },
}