import React from 'react';
import { Container, Grid, Typography } from '@mui/material';

import { Banner } from '../../components';
import { banner555 } from '../../assets';
import usePageTitle from '../../hooks/use-page-title';

function PrivacyPolicy() {
    usePageTitle("Privacy Policy");
    return (
        <>
            <Banner
                bgImage={banner555}
                title="Privacy Policy"
            />
            <Container maxWidth="100%" sx={styles.container} >
                <Grid container spacing={2} pt={4}>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography fontWeight="600" fontSize={22} children="Legal Information" />
                    </Grid>
                    <Grid item md={10} sm={9} xs={12}>
                        <Typography fontSize={{md:18,xs:16}}>
                            Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodales purus sed habitant viverra purus egestas suspendisse dictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus. Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodales purus sed habitant viverra purus egestas suspendisse dictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus.Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodales purus sed habitant viverra purus egestas suspendisse dictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus. Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodales purus sed habitant viverra purus egestas suspendisse dictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus.
                            <br />
                            <br />
                            Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodales purus sed habitant viverra purus egestas suspendisse dictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus. Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodaledictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus.
                            <br />
                            <br />
                            Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodales purus sed habitant
                            <br />
                            <br />
                            Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodales purus sed habitant viverra purus egestas suspendisse dictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus. Lorem ipsum dolor sit amet consectetur. Massa eget tincidunt mauris rhoncus ullamcorper. Phasellus gravida eget dui morbi. Lorem nisi posuere pharetra velit cras dui adipiscing. Sodaledictum. Massa eget dignissim sed et praesent et aliquet. Cras odio in tempus quam quis. Faucibus.
                        </Typography>
                    </Grid>
                </Grid>
            </Container >
        </>
    );
}

const styles = {
    container: { width: "100%", pb: 3, backgroundColor: "#fff" }
}

export default PrivacyPolicy;