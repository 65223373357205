import React from 'react';
import { Grid, Typography, Container, Stack, Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

import { DashboardHeader } from '../../components';
import usePageTitle from '../../hooks/use-page-title';
import { useSelector } from 'react-redux';

function Dashboard() {
    usePageTitle("Dashboard");
    const {user}=useSelector(state=>state.appReducer);

    return (
        <>
            <DashboardHeader />
            <Container maxWidth="xxl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography children="Parlour Panel" sx={styles.title} />
                        <Typography children={`Welcome Back, ${user?.full_name||"[name]"}`} sx={styles.subTitle} />
                        <Typography children="Here’s what’s happening with your Parlour today." sx={styles.text} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} mt={2}>
                            <Grid item md={3} sm={6} xs={12}>
                                <ListItem sx={styles.card}>
                                    <ListItemAvatar>
                                        <Avatar variant="rounded" sx={{ bgcolor: "#8AC004" }}>
                                            <CallMadeIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Number Of Visitos" secondary="25" sx={styles.cardText} />
                                </ListItem>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <ListItem sx={styles.card}>
                                    <ListItemAvatar>
                                        <Avatar variant="rounded" sx={{ bgcolor: "primary.main" }}>
                                            <CallReceivedIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Time User Spent" secondary="10 Hours" sx={styles.cardText} />
                                </ListItem>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <ListItem sx={styles.card}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: "transparent" }}>
                                            <StarRoundedIcon sx={{ fontSize: 50, color: "#F8A401" }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Total Review" secondary="12 Review" sx={styles.cardText} />
                                </ListItem>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <ListItem sx={styles.card}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: "transparent" }}>
                                            <StarRoundedIcon sx={{ fontSize: 50, color: "#F8A401" }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Total Ratings" secondary="4.7" sx={styles.cardText} />
                                </ListItem>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Dashboard;

const styles = {
    title: {
        fontSize: { md: 28, xs: 22 },
        mt:2,
    },
    subTitle: {
        fontSize: { md: 26, xs: 20 },
        mt:2,
    },
    text: {
        fontSize: 14,
        color:"#A6A6A6",
    },
    card: {
        border: "1px solid #ccc",
        borderRadius: 3,
        py: 3,
        transition:"0.2s all ease-in-out",
        boxShadow:{md:"0px 10px 15px #00000021",xs:"0px 3px 9px #00000014"},
        border:0,
        // "&:hover":{
        //     transform:"scale(1.01)"
        // }
    },
    cardText: {
        "p.MuiTypography-root": {
            color: "#000",
            fontSize: { md: 24, xs: 19 }
        },
        "span": {
            color: "#333",
            fontSize: { md: 16, xs: 14 }
        }
    }
}