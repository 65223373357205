import { styled, keyframes } from "@mui/system";

const waveAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-0.1em);
  }
  20% {
    transform: translateY(0.1em);
  }
  30% {
    transform: translateY(-0.05em);
  }
  40% {
    transform: translateY(0.05em);
  }
  50% {
    transform: translateY(-0.025em);
  }
  60% {
    transform: translateY(0.025em);
  }
  70% {
    transform: translateY(-0.0125em);
  }
  80% {
    transform: translateY(0.0125em);
  }
  90% {
    transform: translateY(-0.00625em);
  }
  100% {
    transform: translateY(0);
  }
`;

const RootContainer = styled("div")`
  position: relative;
  z-index:1400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #880808;
  color: #fff;
`;

const Title = styled("div")`
  margin-top: 0.6em;
  font-size:4rem;
  text-align: center;
`;

const AnimatedLetter = styled("span")`
  display: inline-block;
  animation: ${waveAnimation} 1.5s ease-in-out infinite;
  font-weight:bold;
 
`;

const SplashScreen = () => {
  return (
    <RootContainer>
      <Title>
        <AnimatedLetter sx={{ animationDelay: "0.1s" }}>P</AnimatedLetter>
        <AnimatedLetter sx={{ animationDelay: "0.15s" }}>A</AnimatedLetter>
        <AnimatedLetter sx={{ animationDelay: "0.2s" }}>R</AnimatedLetter>
        <AnimatedLetter sx={{ animationDelay: "0.25s" }}>L</AnimatedLetter>
        <AnimatedLetter sx={{ animationDelay: "0.3s" }}>O</AnimatedLetter>
        <AnimatedLetter sx={{ animationDelay: "0.35s" }}>U</AnimatedLetter>
        <AnimatedLetter sx={{ animationDelay: "0.4s" }}>R</AnimatedLetter>
        <AnimatedLetter sx={{ animationDelay: "0.45s" }}>S</AnimatedLetter>
      </Title>
    </RootContainer>
  );
};

export default SplashScreen;
