import React from "react";
import { Banner, ContactForm } from "../../components";
import { HomeBanner, FormSvg } from "../../assets";
import { Box, Grid } from "@mui/material";
import usePageTitle from "../../hooks/use-page-title";

const ContactUs = () => {
  usePageTitle("Contact us")

  return (
    <div>
      <Banner
        bgImage={HomeBanner}
        title="Contact Us"
        subText="Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet"
        buttonText="Visit"
      />
      {/* Form */}
      <Grid
        container
        justifyContent="center"
        spacing={4}
        py={5}
        px={{ sm: 4, xs: 2 }}
      >
        <Grid item md={6} sm={12} align="center" sx={{ overflow: "hidden" }}>
          <Box height={{ sm: "auto", xs: "200px" }}>
            <Box
              component={"img"}
              src={FormSvg}
              sx={{
                overflow: "hidden",
                maxWidth: { sm: "none", xs: "100%" },
                transform: {
                  lg: "translate(0%,0%)",
                  md: "translate(-20%,00%)",
                  sm: "auto",
                  xs: "translate(0%,00%)",
                },
              }}
              alt="form-svg"
            />
          </Box>
        </Grid>
        <Grid item md={6} sm={12} align="center">
          {/* Contact Form */}
          <ContactForm />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
