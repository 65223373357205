import React, { useState } from "react";
import { Button, Modal, Typography } from "@mui/material";

const DialogWrapper = ({ modalContent, handleClose }) => {
  return (
    <div>
      <Modal open={true} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "16px",
            outline: "none",
          }}
        >
          {modalContent}
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DialogWrapper;
