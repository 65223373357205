import { Box } from '@mui/material';
import React from 'react';
import {  Footer, Header } from '../components';

function PrimaryLayout({ children }) {
    return (
        <Box >
            <Header />
            <Box component="main" >{children}</Box>
            <Footer/>
        </Box>
    );
}

export default PrimaryLayout;