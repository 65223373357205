import React from "react";
import { Avatar, Box, Rating, Button, Stack, Typography } from "@mui/material";
import moment from "moment/moment";

const ReviewCard = ({ data }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const readMore = () => setIsExpanded(!isExpanded)

  const truncatedParagraph = data?.comment?.length ? data?.comment.slice(0, 150) : "";

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "295px",
        border: " 1px solid #E7EAEC",
        p: { lg: 3, md: 2, sm: 2, xs: 3 },
      }}
    >
      <Stack direction="row" justifyContent={"space-between"}>
        <Avatar
          src={data?.user?.avatar}
          variant="square"
          sx={{
            width: "68px",
            height: "68px",
          }}
        >{""}</Avatar>
        <Box>
          <Rating name="read-only" readOnly value={data?.rating} />
          <Typography
            children={moment(data?.createdAt).format("MMM D YYYY")}
            sx={{textAlign:"end",fontSize:"12px",color:"#ccc"}}
          />
        </Box>
      </Stack>
      <Typography
        variant="h6"
        color="initial"
        fontSize={24}
        fontWeight={600}
        my={1}
      >
        {data?.user?.name}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="initial"
        fontSize={16}
        fontWeight={400}
      >
        {!isExpanded ? truncatedParagraph : data?.comment}
            {data?.comment?.length > 150
              ? <Button onClick={readMore} variant="text"> {isExpanded ? " read less" : " ...read more"}.</Button> : ""}
      </Typography>
    </Box>
  );
};

export default ReviewCard;
