import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, useMediaQuery } from "@mui/material";

const ModalWrapper = ({ children, open = false, handleClose = () => { }, title }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ "& .MuiPaper-root": { borderRadius: "24px !important" } }}
      >
        {" "}
        <DialogTitle sx={{ m: 0, p: 2, mt: 1 }}>
          {title && title}
          {matches && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                backgroundColor:"#D9D9D9",
                color:"black",
                ":hover":{
                backgroundColor:"#D9D9D9",

                }
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ m: 0, p: 2, }}> {children}</DialogContent>
      </Dialog>
    </>
  );
};

export default ModalWrapper;
