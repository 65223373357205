import React, { useState } from 'react';
import { Container, Grid, Stack, Typography, InputAdornment, Box, InputBase, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { Banner, InputField, PostCard } from '../../components';
import { blogBanner } from '../../assets';
import { postData } from './data';
import usePageTitle from '../../hooks/use-page-title';

function Blog() {
    usePageTitle("Blog");
    const [search, setSearch] = useState("");

    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    }

    const searchInRecords = (e) => {
        e.preventDefault();
        console.log("search in records : ", search);
    }

    const searchBar = () => (
        <Stack direction="column" justifyContent="end" alignItems="flex-end" gap={2}>
            <Box
                component="form"
                onSubmit={searchInRecords}
                sx={{ display: 'flex', alignItems: 'center', width: 400, borderRadius: 1, overflow: "hidden", border: "2px solid #ccc" }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    value={search}
                    onChange={handleSearchInput}
                    placeholder="Search...."
                    inputProps={{ 'aria-label': 'search for latest posts' }}
                />
                <Button variant="contained"
                    sx={{
                        borderRadius: 0
                    }}
                    type="submit" children={<SearchIcon />} />
            </Box>
            <Box component="form" onSubmit={searchInRecords}>
                <InputField placeholder="Search..."
                    size="small"
                    value={search}
                    onChange={handleSearchInput}
                    fullWidth={false}
                    inputStyle={{
                        width: 400,
                        "& .MuiOutlinedInput-root": {
                            paddingRight: 0,
                            borderRadius: "5px !important"
                        }
                    }}
                    InputProps={
                        {
                            endAdornment:
                                < InputAdornment position="start" >
                                    <IconButton
                                        sx={{ color: "primary.main" }}
                                        type="submit"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                        }
                    }
                />
            </Box>
        </Stack>
    )

    return (
        <>
            <Banner
                bgImage={blogBanner}
                bgPosition={{ md: "center", xs: "center right" }}
                title="Here you can get latest Updates"
                subText="Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet"
            />
            <Container maxWidth="100%" sx={styles.container} >
                <Grid container spacing={2} pt={4}>
                    <Grid item sm={6} xs={12}>
                        <Typography fontWeight="600" fontSize={22} children="Latest" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        {searchBar()}
                    </Grid>
                </Grid>
                <Grid container spacing={2} pt={4}>
                    {Array(6).fill(postData).map((item, i) => {
                        return (
                            <Grid item md={4} sm={6} xs={12} key={i}>
                                <PostCard data={item} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Container >
        </>
    );
}

const styles={
    container:{ width: "100%", pb:3, backgroundColor: "#fff" }
}

export default Blog;