import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const DashServiceCard = ({ data,isActive=false, handleClick: propsClick = () => { } }) => {

  return (
    <div>
      <Card
        sx={{
          maxWidth: { md: "320px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          ":hover": {
            boxShadow: "0px 3px 40px rgba(0, 0, 0, 0.07)",
          },
          pt: 4
        }}
      // elevation={0}
      >
        <Stack
          sx={{
            width: "100px",
            background: "#F1F1F14F",
            justifyContent: "center",
            placeItems: "center",
            borderRadius: "100vw ",
          }}
        >
          <CardMedia
            component="img"
            image={data?.icon?.url}
            alt={`icon for ${data?.title} service`}
          />
        </Stack>
        <CardContent>
          <Typography
            variant="h4"
            fontSize={{ sm: 25, xs: 20 }}
            fontWeight={500}
            color="initial"
            textAlign={"center"}
          >
            {" "}
            {data?.title}
          </Typography>
          <Typography
            variant="body1"
            component={"p"}
            fontSize={"1rem"}
            fontWeight={400}
            color="#7E7D7A"
            align="center"
            mt={2}
          >
            {data?.description?.length <= 100
              ? data?.description
              : data?.description.substr(0, 100) + "..."}
          </Typography>
          <Stack mt={2}>
            <Button
              variant="outlined"
              onClick={() => propsClick(data)}>
              {isActive?"Deactivate Service":"Activate Service"}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default DashServiceCard;
