import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const Banner = ({ bgImage, title, logo, subText, bgPosition = "center", buttonText, children, ...props }) => {
    return (
        <Box component="section" >
            <Container maxWidth="100%" sx={{
                width: "100%",
                background: `url(${bgImage})`,
                backgroundPosition: bgPosition,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }}>
                <Container maxWidth="100%" sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", py: { md: 30, sm: 15, xs: 10 } }}>
                    {!!children ?
                        children
                        : <Box sx={{ maxWidth: { md: "50%", sm: "65%", xs: "100%" }, }}>
                            {!!logo && <Box>
                                <Box component="img" src={logo} width={{ sm: "auto", xs: "90px" }} maxWidth="180px" />
                            </Box>}
                            {!!title && <Typography variant="h1" color="white" fontWeight={600} fontSize={{ xl: 68, md: 53, sm: 40, xs: 26 }} my={1}>
                                {title}
                            </Typography>}
                            {subText && <Typography variant="h1" color="white" fontWeight={400} lineHeight={1.3} fontSize={{ sm: 20, xs: 14 }} mb={3}>
                                {subText}
                            </Typography>}
                            {buttonText && <Button {...props} variant='contained' sx={{ fontSize: { md: 22, sm: 16, xs: 12 }, fontWeight: 500, py: 1, px: 5, display: { sm: "block", xs: "none" } }}>
                                {buttonText}
                            </Button>}
                        </Box>}
                </Container>

            </Container>
        </Box>
    )
}

export default Banner
