import React from "react";
import {
  Box,
  Grid,
  InputBase,
  Paper,
  Stack,
  Typography,
  Button,
  Divider,
  ButtonGroup,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

import { FacebookSvg, Logo, TwitterSvg, VimeoSvg, YoutubeSvg } from "../../assets";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box pb={{ sm: 5, xs: 2, backgroundColor: "#FAFAFA" }} component="footer">
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={300}
      >
        <Grid item xl={8} lg={10} md={11}>
          <Box
            sx={{
              width: "100%",
              minHeight: "200px",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "1px 10px 30px -12px rgba(0,0,0,0.5)",
              borderRadius: "10px",
            }}
            component={Paper}
          >
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              px={{ lg: 10, md: 3 }}
            >
              <Grid item md={6} xs={11}>
                <Typography
                  variant="h3"
                  color="#0A142F"
                  fontFamily="Parisienne"
                  textAlign={{ md: "left", sm: "center", xs: "center" }}
                  fontSize={"clamp(2rem, 1.8rem + 1vw, 3rem);;"}
                >
                  Subscribe Newsletters
                </Typography>
              </Grid>
              <Grid item md={6} xs={11}>
                <Grid
                  container
                  sx={{
                    border: "2px solid #e6e7ea",
                    width: "100%",
                    px: 1,
                    py: 1,
                    borderRadius: "5px",
                  }}
                  alignItems="center"
                >
                  <Grid item lg={7.5} md={7} sm={8} xs={8}>
                    <InputBase
                      sx={{ height: "100% ", pl: 2 }}
                      fullWidth
                      placeholder="Enter your email"
                    />
                  </Grid>
                  <Grid item lg={4.5} md={5} sm={4} xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ py: 1.5, fontSize: { sm: "1rem", xs: "0.5rem" } }}
                    >
                      Subcribe Now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={3} md={3} sm={5} xs={12}>
          <Stack
            sx={{
              width: "100%",
              minHeight: { md: "240px", sm: "180px", xs: "120px" },
              placeItems: "center",
              justifyContent: "center",
              display: { sm: "flex", xs: "none" },
            }}
          >
            <Box component={"img"} src={Logo} sx={{ width: "207px" }} />
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Stack
            sx={{
              width: "100%",
              minHeight: { md: "240px", sm: "180px", xs: "120px" },
              justifyContent: "center",
            }}
            pl={{ xs: 3.2, sm: 0 }}
          >
            <Typography
              variant="h4"
              color="initial"
              fontSize={"clamp(1rem, 0.9rem + 0.5vw, 1.5rem);"}
            >
              Suit no # 508, 5th Floor, Mashriq Center, Gulshan Karachi.
            </Typography>
            <Divider
              sx={{ my: 3, width: "112px", border: 1, color: "primary.main" }}
            />
            <Stack direction="row" alignItems="center" my={1} gap={1}>
              <MailIcon />
              <Typography
                variant="body1"
                color="initial"
                fontSize={{ md: 18, sm: 16 }}
              >
                contact@parlours.info
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <LocalPhoneIcon />
              <Typography
                variant="body1"
                color="initial"
                fontSize={{ md: 18, sm: 16 }}
              >
                +1000 1234 123
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={3} md={5} sm={12}>
          <Box
            sx={{
              width: "100%",
              minHeight: { md: "240px", sm: "180px", xs: "120px" },
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "180px",
                }}
              >
                <Box component={"ul"} sx={{ listStyle: "none" }}>
                  {["Home", "Blogs", "Parlours", "Contact Us"].map(
                    (each, i) => {
                      return (
                        <Box component={"li"} my={1} key={i}>
                          <Link style={{ textDecoration: "none" }}>
                            <Typography
                              variant="body2"
                              color="initial"
                              fontSize={{ lg: 18, md: 16 }}
                            >
                              {each}
                            </Typography>
                          </Link>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: { sm: "flex", xs: "none" },
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "180px",
                }}
              >
                <Box component={"ul"} sx={{ listStyle: "none" }}>
                  {["Facebook", "Twitter", "Linkedin", "Instagram"].map(
                    (each, i) => {
                      return (
                        <Box component={"li"} my={1} key={i}>
                          <Link style={{ textDecoration: "none" }}>
                            <Typography
                              variant="body2"
                              color="initial"
                              fontSize={{ md: 18, sm: 16 }}
                            >
                              {each}
                            </Typography>
                          </Link>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="initial"
                  fontSize={{ md: 18, sm: 16 }}
                  textAlign="center"
                >
                  © 2023 parlours.info All rights reserved.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          <Box sx={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent="center" p={2}>
              <Button
                component={"a"}
                href="/"
                children={
                  <Box component={"img"} src={FacebookSvg} alt="FacebookSvg"/>
                }
                color="secondary"
              />
              <Button
                component={"a"}
                children={
                    <Box component={"img"} src={TwitterSvg} alt="TwitterSvg"/>
                }
                color="secondary"
              />

              <Button
                component={"a"}
                children={
                    <Box component={"img"} src={YoutubeSvg} alt="YoutubeSvg"/>

                }
                color="secondary"
              />
              <Button
                component={"a"}
                children={
                    <Box component={"img"} src={VimeoSvg} alt="VimeoSvg"/>

                }
                color="secondary"
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
