import React, { useState, useEffect } from 'react';
import { Grid, Typography, Container, Box, Stack, Button, Badge, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import HelpIcon from '@mui/icons-material/Help';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { DashboardHeader, FileUploader, InputField, MapProvider } from '../../../components';
import usePageTitle from '../../../hooks/use-page-title';
import apiManager from '../../../services/api-manager';
import { handleLoader, setToast, setUser } from '../../../store/reducer';
import useLocation from '../../../hooks/use-location';
import AutoCompletePlace from '../../../components/auto-complete-place/auto-complete-place';

function DashParlourForm() {
    usePageTitle("Parlour Form");
    const [formData, setFormData] = useState(initialState);
    const { user } = useSelector(state => state.appReducer);
    const hasCoords = user?.location?.coordinates.length ? true : false;
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();
    const [location,hasPermission] = useLocation();
    const [coords, setCoords] = useState({});

    useEffect(() => {
        if (!!hasCoords) {
            setCoords({ lng: user?.location?.coordinates[0], lat: user?.location?.coordinates[1] })
        } else if (!!location) {
            setCoords(location)
        }
    }, [location]);

    useEffect(() => {
        async function init() {
            dispatch(handleLoader(true));
            try {
                let { data } = await apiManager({ path: `parlour/${user._id}`, method: "get" })
                setFormData(prev => ({ ...prev, ...data?.data?.meta, banner_image: null, banner_image_url: data?.data?.meta?.banner_image?.url, logo: null, logo_url: data?.data?.meta?.logo?.url }));
            } catch (error) {
                console.log("🚀 ~ file: dash-parlour-form.js:36 ~ init ~ error:", error)
            } finally {
                dispatch(handleLoader(false));
            }
        }
        init();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    const handleImage = (files, _for) => {
        const file = files[0];
        if (_for === "logo") {
            return setFormData(prev => ({ ...prev, logo: file, logo_url: URL.createObjectURL(file) }))
        }
        setFormData(prev => ({ ...prev, banner_image: file, banner_image_url: URL.createObjectURL(file) }))
    }

    const handleForm = async (e) => {
        e.preventDefault();
        dispatch(handleLoader(true));
        setFormErrors({})
        try {
            let { data } = await apiManager({ method: "post", path: "parlour/update-details", params: { ...formData, longitude: coords.lng, latitude: coords.lat }, header: { 'Content-Type': 'multipart/form-data' } })
            dispatch(setUser(data?.data));
            dispatch(setToast({ type: "success", message: data?.message }))
        } catch (error) {
            if (error?.response?.status === 422) {
                setFormErrors(error?.response?.data?.error)
            }
            dispatch(setToast({ type: "error", message: error?.response?.data?.message }))
        } finally {
            dispatch(handleLoader(false));
        }
    }

    return (
        <>
            <DashboardHeader />
            <Container maxWidth="xxl" >
                <Grid container spacing={2} pb={2}>
                    <Grid item xs={12}>
                        <Typography children="Personal Profile" sx={styles.subTitle} />
                        <Typography children="Lorem ipsum dolor sit amet consectetur. Nullam bibendum." sx={styles.text} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" onSubmit={handleForm} maxWidth="850px">
                            {user?.meta?.profile_complete ? null :
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <FiberManualRecordIcon sx={{ fontSize: 13 }} color="error" />

                                    <Typography sx={{ fontSize: 18 }} mb={1}>Profile Incomplete</Typography>
                                    <Tooltip title="Please fill the required fields (Title, Tagline, Description, Banner, Logo, Address, Location) to complete your profile. In case of incomplete information, your parlour will not be shown to users." placement="top">
                                        <HelpIcon sx={{ fontSize: 16 }} />
                                    </Tooltip>
                                </Stack>}
                            <Stack direction="column" spacing={2} mb={4} mt={2}>
                                <InputField placeholder="Title..." labelTop="Title" required br={1} error={formErrors?.title} value={formData?.title} name="title" onChange={handleInputChange} />
                                <InputField placeholder="Tagline..." labelTop="Tagline" required br={1} error={formErrors?.tagline} value={formData?.tagline} name="tagline" onChange={handleInputChange} />
                                <InputField placeholder="Description..." labelTop="Description" rows={4} multiline error={formErrors?.description} required br={1} value={formData?.description} name="description" onChange={handleInputChange} />
                                <FileUploader label="Select the banner" callBack={files => handleImage(files, "banner")} />
                                <FileUploader label="Select the logo" callBack={files => handleImage(files, "logo")} />
                                <AutoCompletePlace
                                    labelTop="Address"
                                    required
                                    size="small"
                                    error={formErrors?.address}
                                    initValue={user?.meta?.address}
                                    callBack={(e) => setFormData(prev => ({ ...prev, address: e }))}
                                />
                                <Box>
                                    <Typography mb={1}>Pin Location</Typography>
                                    {(coords?.lat && coords?.lng) ? <MapProvider
                                        coords={coords}
                                        handleClick={(e) => {
                                            setCoords({ lat: e.lat, lng: e.lng })
                                        }}
                                        height="400px"
                                    /> :
                                        <Typography color="text.secondary">Waiting for location coordinates.</Typography>
                                    }
                                </Box>
                                <InputField placeholder="Url..." labelTop="Facebook" br={1} value={formData?.facebook_url} error={formErrors?.facebook_url} name="facebook_url" onChange={handleInputChange} />
                                <InputField placeholder="Url..." labelTop="Twitter" br={1} value={formData?.twitter_url} error={formErrors?.twitter_url} name="twitter_url" onChange={handleInputChange} />
                                <InputField placeholder="Url..." labelTop="Snapchat" br={1} value={formData?.snapchat_url} error={formErrors?.snapchat_url} name="snapchat_url" onChange={handleInputChange} />
                                <InputField placeholder="Url..." labelTop="Youtube" br={1} value={formData?.youtube_url} error={formErrors?.youtube_url} name="youtube_url" onChange={handleInputChange} />
                            </Stack>
                            <Button variant="contained" sx={{ px: 10 }} type="submit">
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default DashParlourForm;

const styles = {
    subTitle: {
        fontSize: { md: 26, xs: 20 },
        mt: 2,
    },
    text: {
        fontSize: 14,
        color: "#A6A6A6",
    },
}

const initialState = {
    title: "",
    tagline: "",
    description: "",
    banner_image: null,
    banner_image_url: "",
    logo: null,
    logo_url: "",
    facebook_url: "",
    twitter_url: "",
    snapchat_url: "",
    youtube_url: "",
}