import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { DummyCraousle } from "../../assets";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import moment from "moment";
import { TestimonialsPlaceholder } from "../../placeholders";

const TestimonialsCarousel = ({ data = [], isLoading }) => {
  const slider = React.useRef(null);

  const settings2 = {
    className: "center",
    dots: true,
    centerMode: true,
    infinite: true,
    centerPadding: "250px",
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
    },
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "100px",
          slidesToShow: 1,
          speed: 500,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "50px",
          slidesToShow: 1,
          speed: 500,
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerMode: false,
          infinite: true,
          slidesToShow: 1,
          speed: 500,
        },
      },
    ],
  };

  const sliderNavigator = () => {
    return <Stack
      mt={2}
      direction={"row"}
      justifyContent={"center"}
      alignItems="center"
      position={"relative"}
      sx={{ display: { md: "flex", sm: "none", xs: "none" } }}
    >
      <Box sx={{ position: "absolute", left: { lg: "37%", md: "30%" } }}>
        <IconButton onClick={() => slider?.current?.slickPrev()}>
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "3rem", color: "primary.main" }}
          />
        </IconButton>
      </Box>

      <Box sx={{ position: "absolute", right: { lg: "37%", md: "30%" } }}>
        <IconButton onClick={() => slider?.current?.slickNext()}>
          <KeyboardArrowRightIcon
            sx={{ fontSize: "3rem", color: "primary.main" }}
          />
        </IconButton>
      </Box>
    </Stack>
  }

  return (
    <div className="testimonials">
      <Slider {...settings2} ref={slider}>
        {isLoading && Array(5).fill("").map((_, i) => <TestimonialsPlaceholder key={i} />)}
        {data.map((item, i) => <CardItem item={item} key={i} />)}
      </Slider>
      {sliderNavigator()}
    </div>
  );
};

const CardItem = ({ item }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const readMore = () => setIsExpanded(!isExpanded)

  const truncatedParagraph = item?.comment?.length ? item?.comment.slice(0, 150) : "";

  return (
    <Box
      sx={{
        background: "#fff",
      }}
    >
      <Stack
        direction={{ sm: "row", xs: "row" }}
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: { sm: "283px", xs: 180 }, p: 3 }}
        spacing={{ sm: 5, xs: 1 }}
      >
        <Box
          component="img"
          src={item?.parlour?.meta?.logo?.url}
          width={{ xs: 52, sm: "250px" }}
          sx={{ minWidth: { sm: "150px" } }}
        />
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h3"
            color="initial"
            fontSize={{ md: 24, sm: 18, xs: 12 }}
            fontWeight={600}
          >
            {item?.parlour?.meta?.title}
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            fontSize={{ md: 18, sm: 16, xs: 8 }}
            fontWeight={400}
            my={1}
          >
            {!isExpanded ? truncatedParagraph : item?.comment}
            {item?.comment?.length > 150
              ? <Button onClick={readMore} variant="text"> {isExpanded ? " read less" : " ...read more"}.</Button> : ""}
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            fontSize={12}
            fontWeight={400}
          >
            {moment(item?.createdAt).format("MMM DD yyyy")}
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}

export default TestimonialsCarousel;
