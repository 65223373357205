import React from "react";
import GoogleMapReact from 'google-map-react';
import { Box } from "@mui/material";
import RoomIcon from '@mui/icons-material/Room';

const MapProvider = ({
    handleClick = () => { },
    coords,
    mapZoom = 14,
    height = "180px",
}) => {

    const Marker = () => (
        <RoomIcon color="primary" />
    );

    return (
        <Box sx={{ position: "relative" }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY,
                    libraries:['places'],
                    language: "en",
                }}
                defaultCenter={coords}
                defaultZoom={mapZoom}
                style={{ height: height, width: "100%" }}
                onClick={handleClick}
            >
                <Marker
                    lat={coords?.lat}
                    lng={coords?.lng}
                />
            </GoogleMapReact>
        </Box>
    );
};


export default MapProvider;