import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

import {
  Banner,
  ContactForm,
  ParlourDisplayCard,
  TestimonialsCarousel,
} from "../../components";
import { FormSvg } from "../../assets";
import { size } from "../../assets/font-sizes";
import { PrimaryCarouselSetting } from "../../constant";
import usePageTitle from "../../hooks/use-page-title";
import { BannerPlaceholder, ParlourDisplayCardPlaceholder } from "../../placeholders";
import apiManager from "../../services/api-manager";
import { setToast } from "../../store/reducer";

function Home() {
  usePageTitle("Home")
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [topRated, setTopRated] = useState([]);
  const [nearParlours, setNearParlours] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const location = localStorage.getItem("@Location");
  const dispatch = useDispatch();
  
  useEffect(() => { 
    async function init() {
      setIsLoading(true);
      try {
        const { lat = '', lng = '' } = JSON.parse(location||{});
        let { data } = await apiManager({ method: "get", path: `user/parlour/get-home-data?lat=${lat}&lng=${lng}` });
        setTopRated(data?.data?.top_rated_parlours);
        setNearParlours(data?.data?.near_parlours);
        setTestimonials(data?.data?.random_reviews);
      } catch (error) {
        dispatch(setToast({ type: "error", message: error?.response?.data?.message }));
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, [location]);

  return (
    <Box>
      {isLoading && <BannerPlaceholder />}
      {topRated?.length && <Box className="hero" component="section">
        <Slider {...PrimaryCarouselSetting}>
          {topRated.map(item => {
            return <Banner
              key={item?._id}
              bgImage={item?.meta?.banner_image?.url}
              logo={item?.meta?.logo?.url}
              title={item?.meta?.title}
              subText={item?.meta?.tagline}
              buttonText="Visit"
              onClick={() => {
                navigate(`parlours/${item?._id}`)
              }}
            />
          })}
        </Slider>
      </Box>}

      {/* near you cards */}
      <Box>
        <Box sx={{ background: "white", px: { sm: 4, xs: 2 }, py: 4 }}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            mb={2}
            alignItems="center"
          >
            <Typography
              variant="h2"
              color="initial"
              fontWeight={400}
              fontSize={size.subHeading}
              fontFamily={"Parisienne"}
            >
              Nearby Parlours
            </Typography>
            <Button variant="text" onClick={() => navigate('/parlours')}>View All</Button>
          </Stack>
          <Grid container spacing={{ sm: 4, xs: 2 }}>
            {isLoading
              ? Array(4)
                .fill("_")
                .map((_, i) => (
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={i}>
                    <ParlourDisplayCardPlaceholder
                      handelPress={() => { }}
                    />
                  </Grid>
                ))
              : null}
            {nearParlours.length &&
              nearParlours.map((item, i) => (
                <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={i}>
                  <ParlourDisplayCard
                    data={item}
                    handelPress={() => navigate(`parlours/${item?._id}`)}
                  />
                </Grid>
              ))
            }
          </Grid>
        </Box>

        {/* near you cards */}

        {/* Testimonials */}
        <Box sx={{ pt: 10, pb: 10, backgroundColor: "secondary.light" }}>
          <Typography
            variant="h3"
            color="initial"
            textAlign={"center"}
            fontSize={35}
            fontWeight={400}
            fontFamily={"Parisienne"}
          >
            Testimonials
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            component={"p"}
            textAlign={"center"}
            fontSize={{ sm: 18, xs: 8 }}
            fontWeight={400}
          >
            Lorem ipsum dolor sit amet consectetur. Tincidunt leo a viverra
            nulla purus ultrices diam. Pulvinar <br /> et nunc lectus aliquam
            hendrerit egestas
          </Typography>
          {testimonials.length && <Box width={"100%"} mt={5}>
            <TestimonialsCarousel isLoading={isLoading} data={testimonials} />
          </Box>}
        </Box>
        {/* Testimonials */}

        {/* Form */}
        <Grid
          container
          justifyContent="center"
          spacing={4}
          py={5}
          px={{ sm: 4, xs: 2 }}
        >
          <Grid item md={6} sm={12} align="center" sx={{ overflow: "hidden" }}>
            <Box height={{ sm: "auto", xs: "200px" }}>
              <Box
                component={"img"}
                src={FormSvg}
                sx={{
                  overflow: "hidden",
                  maxWidth: { sm: "none", xs: "100%" },
                  transform: {
                    lg: "translate(0%,0%)",
                    md: "translate(-20%,00%)",
                    sm: "auto",
                    xs: "translate(0%,00%)",
                  },
                }}
                alt="form-svg"
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} align="center">
            <ContactForm />
          </Grid>
        </Grid>
      </Box>
      {/* Form */}
    </Box>
  );
}

export default Home;
