

import React from "react";
import { styled } from "@mui/system";
import { ComingSoon } from "../../assets";

const RootContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  background: "black",
});

const Image = styled("img")({
  maxWidth: "100%",
  height: "auto",
});

const ComingSoonPage = () => {
  return (
    <RootContainer>
      <Image src={ComingSoon} alt="Coming Soon" />
    </RootContainer>
  );
};

export default ComingSoonPage;
