import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PrimaryLayout from "../../layout/primary-layout";
import { SplashScreen } from "../../components";
import { accessToken } from "../../constant";
import apiManager from "../../services/api-manager";
import { setToast, setUser } from "../../store/reducer";

function ComponentWrapper({ item = {}, ...props }) {
  const { layout, _for, ele, isProtected, fallBack } = item;
  const [ isLoading, setIsLoading] = useState(true);
  const { isLogged } = useSelector(state => state.appReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLogged && !isLoading && isProtected) {
      navigate(fallBack);
    }

    // if (isLogged && !isLoading && !isProtected) {
    //     navigate(fallBack);
    // }
  }, [isLogged, isLoading, navigate]);

  useEffect(() => {
    async function init() {
      try {
        let token = localStorage.getItem(accessToken);
        if (token) {
          // server request for user authentication
          let { data } = await apiManager({
            method: "get",
            path: "me",
          });
          dispatch(setUser(data?.user));
        }
      } catch (error) {
        if(error.response.status===401){
          localStorage.clear();
        }
        dispatch(
          setToast({ type: "error", message: error?.response?.data?.message })
        );
      } finally {
        setIsLoading(false);
      }
    }
    setTimeout(() => {
      init();
    }, 2000);
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  // restrict authenticated user from routes by the help of role of user
  // if (_for !== "all" && role !== _for) {
  //     alert("You are unauthorized.")
  //     return <></>
  // }

  if (isProtected && layout === "none") {
    return ele;
  }

  if (isProtected && layout === "primary") {
    return <PrimaryLayout children={ele} />;
  }

  if (layout === "primary") {
    return <PrimaryLayout children={ele} />;
  }
  return ele;
}

export default ComponentWrapper;
