import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { Box, Grid } from "@mui/material";
import MagicSliderDots from "react-magic-slider-dots";

import TeamCard from "../team-card/team-card";
import { TeamCardPlaceholder } from "../../placeholders";

export default function ProfileCard({ data = [], isLoading = false }) {
  const settings = {
    dots: true,
    infinite: data.length > 4 ? true : false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    appendDots: (dots) => {
      return (
        <Box sx={{ position: "absolute", width: { md: "77%", sm: "60%" } }}>
          {" "}
          <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
        </Box>
      );
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var owner = null;
  const teams = []

  data.map(item => {
    if (item?.is_owner) {
      owner = item;
    } else {
      teams.push(item);
    }
  })

  return (
    <Grid className="profileCards" container gap={{ md: 1.5, sm: 1, xs: 0 }}>
      <Grid
        item
        lg={2.3}
        md={2.8}
        sm={3.8}
        sx={{ display: { sm: "block", xs: "none" } }}
      >
        {isLoading && <TeamCardPlaceholder />}
        {!!owner && <TeamCard data={owner} />}
      </Grid>
      {teams?.length ? <Grid item lg={9.5} md={8.8} sm={8} xs={12}>
        <Slider {...settings}>
          {isLoading && Array(4).fill("").map((_, i) => <TeamCardPlaceholder key={i} />)}
          {teams.map((each, i) => {
            return <TeamCard key={i} data={each} />;
          })}
        </Slider>
      </Grid> : null}
    </Grid>
  );
}


