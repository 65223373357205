import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Utils from '../../utils/utils';

function PostCard({ data }) {
    const { image_url, description, title, created_at } = data;
    return (
        <Box component={Link} to={`/post/${Utils.generateId()}`} sx={styles.container}>
            <Box sx={styles.innerWrapper}>
                <Box>
                    <Box component="img" sx={{ maxWidth: "100%" }} src={image_url} alt="" />
                </Box>
                <Stack my={2} direction="row" gap={1} alignItems="center">
                    <Typography
                        children={title}
                        sx={styles.title}
                    />
                    <Box sx={styles.line} />
                    <Typography
                        color="#ccc"
                        children={created_at}
                        sx={styles.duration}
                        />
                </Stack>
                <Typography
                    children={description}
                    sx={styles.description}
                    fontSize={18}
                    pb={3}
                />
            </Box>
        </Box>
    );
}
const styles = {
    container: {
        color: "#333",
        textDecoration: "none",
        boxSizing: "border-box",
    },
    innerWrapper: {
        color: "#333",
        textDecoration: "none",
        fontSize: "14px",
        boxSizing: "border-box",
        transition: "0.2s all ease-in-out",
        '&:hover': {
            transform: "scale(1.05)",
        }
    },
    title: {
        color: "primary.main",
        fontSize: "14px"
    },
    duration: {
        color: "#ccc",
        fontSize: "14px",
    },
    description: {
        fontSize: "14px",
        overflow: "hidden",
        textOverflow: "ellipsis", 
        whiteSpace: "nowrap",
        WebkitBoxOrient: "vertical",
    },
    line: {
        bgcolor: "#ccc",
        width: "25px",
        height: "1px"
    }
}

export default PostCard;