import Utils from "../../../utils/utils";

export const reviewsJSON = [
    {
        id: Utils.generateId(),
        imgUrl: "",
        title: "Noor Ul Hasna",
        review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus ",
        createdAt: "",
        status: "pending",
    },
    {
        id: Utils.generateId(),
        imgUrl: "",
        title: "Shahid Khan Yousidf",
        review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus ",
        createdAt: "",
        status: "accepted",
    },
    {
        id: Utils.generateId(),
        imgUrl: "",
        title: "Alvina Mallah",
        review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus ",
        createdAt: "",
        status: "rejected",
    },
]